import actions from "./actions";

const initState = { idToken: null, refreshing: true, user: null };

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      return {
        ...state,
        idToken: action.payload ? action.payload.token : action.token,
        refreshing: false,
      };
    case actions.GET_USER:
      return {
        ...state,
        user: action.payload,
      };
    case actions.LOGOUT:
      return Object.assign({}, initState);
    case actions.REFRESHING_AUTH:
      return {
        ...state,
        refreshing: action.payload,
      };
    default:
      return state;
  }
}
