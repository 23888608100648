import { Row } from "antd";
import React from "react";
import {
  Controller,
  FieldError,
  RegisterOptions,
  useFormContext,
} from "react-hook-form";
import Input from "../components/uielements/input";
import ErrorInputMessage from "./ErrorInputMessage";

type Props = {
  name: string;
  label?: string;
  rules?: RegisterOptions;
  placeholder?: string;
  error?: FieldError;
  style?: any;
  type?: string;
  disabled?: boolean;
  value?: string | number;
  min?: number;
  max?: number;
};
const InputController = ({
  name,
  error,
  label,
  rules,
  placeholder,
  style,
  value,
  ...rest
}: Props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const fieldError = error ? error : errors && errors[name];
  return (
    <Row>
      {label && <h4>{label}</h4>}
      <Controller
        rules={rules}
        control={control}
        name={name}
        render={({ field }) => (
          <Input
            {...field}
            placeholder={placeholder ? placeholder : label?.toLowerCase()}
            style={style}
            value={value || field.value}
            min="0"
            {...rest}
          />
        )}
      />
      <ErrorInputMessage error={fieldError as any} />
    </Row>
  );
};

export default InputController;
