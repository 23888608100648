import React, { useEffect, useRef, useState } from "react";
import Pusher from "pusher-js";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { NOTIFICATION_TYPE, Store } from "react-notifications-component";
import _ from "lodash";
import moment from "moment";
import { audioAlarmRef, audioNotifRef } from "./router";
import { notificationsActions } from "./redux/notifications/actions";
import { chatActions } from "./redux/chat/actions";
import { isAdmin } from "./constants";
import { history } from "./redux/store";
import { State } from "./redux/root-reducer";
import actions from "./redux/planning/actions";
import { AgentShiftStatus, Planning } from "./redux/apis/types";

const role = process.env.REACT_APP_ROLE;

// This is a custom hook to reference the previous state
// of a variable (in this file, used to check previous state
// of `authToken`)
function usePrevious(value: any) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
let isRegisteredPusher = false;
let isRegisteredPusherBeams = false;
const PusherController = () => {
  let chatChannel: any = null;
  let pusher: any = null;
  const dispatch = useDispatch();
  const isRehydrated = true;
  const { user, idToken } = useSelector((state: State) => state.Auth);
  const { planningDetails } = useSelector((state: State) => state.Planning);
  const [planning, setPlanning] = useState<Planning>();

  const isUserLoggedIn = !!idToken;

  const prevAuthToken = usePrevious(idToken);

  useEffect(() => {
    setPlanning(planningDetails);
  }, [planningDetails]);
  useEffect(() => {
    if (idToken && user && user.id && !isRegisteredPusherBeams) {
      isRegisteredPusherBeams = true;
    } else {
      if (!isUserLoggedIn) {
        isRegisteredPusherBeams = false;
        isRegisteredPusher = false;
      }
    }

    if (!idToken && prevAuthToken && idToken !== prevAuthToken) {
      isRegisteredPusher = false;
      isRegisteredPusherBeams = false;
      if (chatChannel) {
        chatChannel.unbind_all();
      }
      if (pusher) {
        pusher.unsubscribe(`private-App.User.${user.id}`);
        // chatChannel.trigger("client-app-state", {
        //   user_id: user.id,
        //   state: "inactive",
        // });
      }
    }
  }, [idToken, user]);

  useEffect(() => {
    if (!isRegisteredPusher && user && user.id && idToken) {
      subscribeChannels();
      isRegisteredPusher = true;
    } else {
      if (isRehydrated && !isUserLoggedIn) {
        isRegisteredPusher = false;
      }
    }
  }, [user]);

  const handleNotificationData = (message: any, dispatch: any) => {
    switch (message.type) {
      case "shift-confirmed":
      case "shift-started":
      case "shift-ended":
        dispatch(
          notificationsActions.handleShiftStatusNotification({ message })
        );

      default: {
        console.log("message type not handled : ", message.type);
      }
    }
  };

  const subscribeChannels = async () => {
    // console.log('messages form pusher : ', messages);

    const token = idToken;
    console.log(
      "process.env.REACT_APP_PUSHER_APP_ID : ",
      process.env.REACT_APP_PUSHER_APP_ID
    );
    const config = {
      appId: process.env.REACT_APP_PUSHER_APP_ID,
      enabledTransports: ["ws"],
      forceTLS: true,
      key: process.env.REACT_APP_PUSHER_KEY,
      secret: process.env.REACT_APP_PUSHER_SECRET,
      cluster: "eu",
      encrypted: true,
      authEndpoint: `${process.env.REACT_APP_BASE_URL}/broadcasting/auth`,
      auth: {
        params: { id_token: token },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    };
    pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY as string, {
      enabledTransports: ["ws"],
      forceTLS: true,
      cluster: "eu",
      // userAuthentication: {
      //   endpoint: `${process.env.REACT_APP_BASE_URL}/broadcasting/auth`,
      //   transport: "jsonp",
      //   params: { id_token: token },
      //   headers: {
      //     Authorization: `Bearer ${token}`,
      //   },
      // },
      authEndpoint: `${process.env.REACT_APP_BASE_URL}/api/pusher/auth`,
      auth: {
        params: { id_token: token },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
      // authTransport: "jsonp",
    });
    var state = pusher.connection.state;
    // console.log('state : ', pusher);
    // Pusher.logToConsole = true;

    pusher.connection.bind("error", function (err: any) {
      console.log("error connecting to pusher");
    });

    chatChannel = pusher.subscribe(`private-App.User.${user.id}`);

    chatChannel.bind("pusher:subscribe", () => {
      console.log("pusher:subscribepusher:subscribe");
    });
    // const self = this;
    chatChannel.bind("pusher:subscription_succeeded", () => {
      console.log("chatChannelsubscribe : ", chatChannel);

      console.log("pusher:connected");
      // chatChannel.trigger("client-app-state", {
      //   user_id: user.id,
      //   state: "active",
      // });
      chatChannel.bind("new-message", (data: any) => {
        console.log("new-messagenew-message : ", data);
        // if (chatChannel)
        //   chatChannel.trigger('client-message-received', {
        //     message_id: data.message.id,
        //   });
        dispatch(chatActions.getNewMessage(data.message));
      });
      chatChannel.bind("channel-created", (data: any) => {
        dispatch(chatActions.getNewChannel({ data: data.message }));
      });
      chatChannel.bind("channel-updated", (data: any) => {
        dispatch(chatActions.getUpdatedChannel({ data: data.message }));
      });
      chatChannel.bind("request-created", (data: any) => {
        // dispatch(
        //   requestsActions.getRequestById(data.message.request_id, "add")
        // );
        audioNotifRef.current.play();
        // showNotification(
        //   "New Request",
        //   data.message.title,
        //   "success",
        //   false,
        //   dispatch,
        //   data.message.request_id,
        //   data.message.identifier,
        //   data.message.username
        // );
        console.log("request-created : ", data);
      });
      chatChannel.bind("new-notification", (data: any) => {
        console.log("data : ", data);
        // dispatch(
        //   requestsActions.getRequestById(data.message.request_id, "add")
        // );
        audioNotifRef.current.play();
        showNotification(
          "Notification",
          data.message.username + " " + data.message.action,
          data.message.type === "sos-status"
            ? "danger"
            : data.message.type === "sos-safe"
            ? "success"
            : "info",
          data.message.type === "sos-status" ? true : false,
          dispatch,
          data.message.request_id,
          data.message.planning_id,
          data.message.identifier,
          data.message.username
        );

        handleNotificationData(data.message, dispatch);
        // console.log("request-created : ", data);
      });
      chatChannel.bind("team-location-updated", (data: any) => {
        if (data.message.event === "team-sos") {
          audioAlarmRef.current.play();
          history.push(`/${role}/tracking`);
        } else if (data.message.event === "team-safe") {
          audioAlarmRef.current.pause();
        }
        // dispatch(requestsActions.updateTeamLocation(data.message));
      });
      chatChannel.bind("request-updated", (data: any) => {
        const { event, status } = data.message;
        console.log("request-updated : ", data.message);
        // dispatch(
        //   requestsActions.updateTeamLocation({
        //     request_id: 98,
        //     request_shift_id: 42,
        //     request_team_id: 43,
        //     longitude: "4.8454807",
        //     latitude: "52.3798773",
        //   })
        // );

        if (event !== "request-history") {
          // dispatch(
          //   requestsActions.getRequestById(data.message.request_id, "update")
          // );
          audioNotifRef.current.play();

          // showNotification(
          //   "",
          //   data.message.title,
          //   event === "request-canceled" || event === "team-sos"
          //     ? "danger"
          //     : event === "team-safe"
          //     ? "success"
          //     : "info",
          //   event === "team-sos" || event === "team-safe" ? true : false,
          //   dispatch,
          //   data.message.request_id,
          //   data.message.identifier,
          //   data.message.username
          // );
        }

        const hash = window.location.hash;
        if (hash.indexOf("/tracking") !== -1) {
          let action = "update";
          if (status === "finished") {
            action = "finished";
          }
          // dispatch(
          //   requestsActions.getActiveRequestById(
          //     data.message.request_id,
          //     action
          //   )
          // );
        }
      });
    });
    // chatChannel.bind('pusher:member_added', function (member) {
    //   // for example:
    //   console.log('member_added : ', member);
    //   //add_member(member.id, member.info);
    // });
    // chatChannel.bind('pusher:member_removed', function (member) {
    //   // for example:
    //   console.log('member_removed : ', member);
    //   //add_member(member.id, member.info);
    // });
    chatChannel.bind("pusher:subscription_error", (status: any) => {
      console.log("subscription_error : ", status);
      // console.log('error outside if ', status);
      // if (status === 408 || status === 503) {
      // retry?
      // }
    });
    chatChannel.bind("pusher:state_change", (status: any) => {
      console.log("state_change : ", status);
      // console.log('error outside if ', status);
      // chatChannel.trigger("client-app-state", {
      //   user_id: user.id,
      //   state: "inactive",
      // });
      // if (status === 408 || status === 503) {
      // retry?
      // }
    });
  };

  return null;
};

export const showNotification = (
  title: string,
  message: string,
  type: NOTIFICATION_TYPE,
  notCancelled: boolean,
  dispatch: any,
  request_id: number,
  planning_id: number,
  identifier: string,
  username: string
) => {
  if (isAdmin) {
    dispatch(
      notificationsActions.updateNotifications({
        message,
        request_id,
        planning_id,
        identifier,
        username,
        created_at: moment().toLocaleString(),
      })
    );
  }

  Store.addNotification({
    title,
    message,
    type: type || "info",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    //dismiss: undefined,
    dismiss: notCancelled
      ? undefined
      : {
          duration: 5000,
          onScreen: true,
          pauseOnHover: true,
        },
  });
};

export default PusherController;
