const actions = {
  SET_ALL_CLIENTS: "SET_ALL_CLIENTS",
  SET_ALL_BRANCHES: "SET_ALL_BRANCHES",
  SET_ALL_CLIENT_ACCOUNTS: "SET_ALL_CLIENT_ACCOUNTS",
  SET_LOCATIONS: "SET_LOCATIONS",
  SET_CONTACTS: "SET_CONTACTS",
  SET_BRANCH_RATES: "SET_BRANCH_RATES",
  setClients: (data) => ({
    type: actions.SET_ALL_CLIENTS,
    payload: data,
  }),
  setBranches: (data) => ({
    type: actions.SET_ALL_BRANCHES,
    payload: data,
  }),
  setClientAccounts: (data) => ({
    type: actions.SET_ALL_CLIENT_ACCOUNTS,
    payload: data,
  }),
  setLocations: (data) => ({
    type: actions.SET_LOCATIONS,
    payload: data,
  }),
  setBranchRates: (data) => ({
    type: actions.SET_BRANCH_RATES,
    payload: data,
  }),
  setContacts: (data) => ({
    type: actions.SET_CONTACTS,
    payload: data,
  }),
};
export default actions;
