import { useMemo } from "react";

import { Invoice } from "../../../redux/apis/types";

export const useFilterInvoice = (invoices: Invoice[], search: string[]) => {
  const filter = useMemo(() => {
    let copy = [...invoices];

    for (let i = 0; i < search.length; i++) {
      // console.log("searchhhhh : ", search);
      copy = copy.filter(
        (item) =>
          item.invoice_number.toString().includes(search[i]) ||
          item.data?.description?.toString().includes(search[i]) ||
          item?.branch?.name.toLowerCase().includes(search[i]?.toLowerCase()) ||
          item?.subcontractor?.name
            .toLowerCase()
            .includes(search[i]?.toLowerCase())
      );
    }

    return copy.map((item) => ({
      ...item,
      key: item.id,
    }));
  }, [invoices, search]);

  return filter;
};
