import { Store } from "redux";
import {
  OAUTH_CLIENT_ID,
  REDIRECT_URI,
} from "../containers/Pages/SignIn/config";
import actions from "./auth/actions";
import { axiosWithoutToken, axiosWithToken } from "./axiosMiddleware";

export const interceptor = (store: Store) => {
  axiosWithToken.interceptors.request.use(
    async (conf: any) => {
      const { idToken } = store.getState().Auth;
      if (idToken) {
        // console.log("idToken : ", idToken);
        conf.headers["Authorization"] = `Bearer ${idToken}`;
        if (!conf.headers["Content-Type"])
          conf.headers["Content-Type"] = "application/json";
      }
      return conf;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  axiosWithToken.interceptors.response.use(
    async (next) => {
      return Promise.resolve(next);
    },
    async (error) => {
      // return Promise.reject(error);
      if (error.response?.status === 401) {
        try {
          const response = await axiosWithoutToken.post("/oauth/token", {
            grant_type: "refresh_token",
            refresh_token: localStorage.getItem("refresh_token"),
            client_id: OAUTH_CLIENT_ID,
            redirect_uri: REDIRECT_URI,
          });

          if (response?.data?.access_token) {
            const { data } = response;
            const originalRequest = error.config;
            if (data.access_token) {
              if (
                !process.env.NODE_ENV ||
                process.env.NODE_ENV === "development"
              ) {
                localStorage.setItem("refresh_token", data.refresh_token);
              }
              store.dispatch(actions.loginSuccess(data.access_token));
              originalRequest.headers.Authorization =
                "Bearer " + data.access_token;
              return axiosWithToken.request(originalRequest);
            }
          } else {
            localStorage.removeItem("isCapitalUserLoggedIn");
            store.dispatch(actions.logout());
            // reset({
            //   index: 1,
            //   routes: [{ name: ROUTES.WELCOME }],
            // });
          }
        } catch (e) {
          localStorage.removeItem("isCapitalUserLoggedIn");
          store.dispatch(actions.logout());
          // reset({
          //   index: 1,
          //   routes: [{ name: ROUTES.WELCOME }],
          // });
        }
      } else {
        return Promise.reject(error);
      }
    }
  );
};

export default {
  interceptor,
};
