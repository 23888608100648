import { Modal } from "antd";
import styled from "styled-components";

const Wrapper = styled(Modal)`
  section {
    margin-bottom: 20px;
  }
`;

export default Wrapper;
