import {
  AppBar,
  Button,
  CircularProgress,
  IconButton,
  ListItem,
  TextField,
  Toolbar,
  Typography,
} from "@material-ui/core";
import "react-chat-elements/dist/main.css";
import ScrollView from "react-inverted-scrollview";
import moment from "moment";
import { Backspace, ChevronLeft, Image } from "@material-ui/icons";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { giftedChatType } from "../../../redux/utils";
import { MessageList } from "react-chat-elements";
import { getRequestStatus } from "../../../redux/utils";
import ChannelDetails from "./ChannelDetails";
import { isMobile } from "react-device-detect";
import { chatActions } from "../../../redux/chat/actions";
import { getMessages } from "../../../redux/chat/selectors";
const empty = [];
const ChatMessage = ({ onClose }) => {
  const [messages, setMessages] = useState([]);
  const fileInput = React.createRef();
  const scrollRef = useRef();
  const scrollBottomRef = useRef();
  const dispatch = useDispatch();
  const [onLoadMore, setLoadMore] = useState(false);
  const {
    currentChannel,
    unreadMessages,
    fetchingMessages,
    messages: paginatedMessages,
  } = useSelector((state) => state.chat);
  const [detailsVisible, setDetailsVisible] = useState(false);
  const [text, setText] = useState("");
  const { user } = useSelector((state) => state.Auth);
  const messagesSelector = useSelector(getMessages);

  useEffect(() => {
    getChatMessages();
  }, [messagesSelector]);

  const getChatMessages = useCallback(async () => {
    try {
      const msgs = await giftedChatType(messagesSelector, currentChannel, user);
      setMessages(msgs);
    } catch (err) {
      console.log("Error occured when fetching books");
    }
  }, [messagesSelector]);

  // useEffect(() => {
  //   if (!onLoadMore) {
  //     scrollBottomRef.current.scrollIntoView({
  //       behavior: "auto",
  //       block: "end",
  //     });
  //   } else {
  //     console.log("scroooooool to ");
  //     // scrollRef.current.scrollTo(100, 100);
  //     //scrollRef.current.scrollTo);
  //   }
  //   // setLoadMore(false);
  // }, [messages]);

  useEffect(() => {
    dispatch(chatActions.getChannelMembers(currentChannel.channel_id));
    dispatch(chatActions.getMessagesHistory(1));
    if (unreadMessages.find((x) => x.channel_id == currentChannel.channel_id))
      dispatch(chatActions.setReadAllMessages(currentChannel.channel_id));
    return () => dispatch(chatActions.setCurrentChannel(null));
  }, []);

  const onPressMessage = (item) => {
    if (item.image) {
      window.open(item.data.uri);
    }
  };

  const onSend = (data = {}) => {
    const newMessage = {
      text,
      created_at: moment().utc().format("YYYY-MM-DD HH:mm:ss"),
      id: Math.random(),
      request_status: getRequestStatus(currentChannel.channels.request),
      name: currentChannel.channels.name,
      channel_id: currentChannel.channel_id,
      user: {
        id: user.id,
        first_name: user.first_name,
        last_name: user.last_name,
      },
      ...data,
    };
    dispatch(chatActions.sendNewMessage(newMessage));
    setText("");
  };

  const onSendPicture = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let newFile = e.target.files[0];
    //reader.onloadend = () => {
    //  setFile(newFile);
    //  setImagePreviewUrl(reader.result);
    // };
    //reader.readAsDataURL(file);

    onSend({
      imageData: {
        image: newFile,
        reason: "Other",
      },
    });
  };

  const onScroll = ({ scrollTop, scrollBottom }) => {
    const y = scrollTop;
    if (
      (y < 20 && paginatedMessages["nextPageUrl"] !== -1) ||
      (!paginatedMessages["nextPageUrl"] && !fetchingMessages)
    ) {
      setLoadMore(true);
      dispatch(chatActions.getMessagesHistory(2));
    }
  };

  return (
    <div
      style={{
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: "#fff",
        zIndex: 99999999999999,
      }}
    >
      <AppBar position="static">
        <Toolbar>
          <IconButton
            edge="start"
            onClick={onClose}
            color="inherit"
            aria-label="menu"
          >
            <ChevronLeft />
          </IconButton>
          <Typography variant="h6" style={{ flexGrow: 1, color: "white" }}>
            {currentChannel.channels.name}
          </Typography>
          <Button onClick={() => setDetailsVisible(true)} color="inherit">
            Members
          </Button>
        </Toolbar>
      </AppBar>
      {/* <div ref={scrollRef} onScroll={onScroll} className="chat-frame"> */}
      <ScrollView
        width={isMobile ? 350 : 500}
        height={420}
        ref={scrollRef}
        onScroll={onScroll}
      >
        {fetchingMessages && (
          <CircularProgress
            size={30}
            color="primary"
            style={{ marginBottom: 20, alignSelf: "center", marginTop: 20 }}
          />
        )}

        {/* {messages.reverse().map((message) => (
          <MessageBox
            key={message.id}
            position={message.user_id == user.id ? "right" : "left"}
            type={message.type}
            text={
              <div>
                <h4>{message.text}</h4>
              </div>
            }
          />
        ))} */}
        <MessageList
          lockable={true}
          toBottomHeight={"100%"}
          onClick={onPressMessage}
          dataSource={messages || empty}
        />
      </ScrollView>
      <div style={{ float: "left", clear: "both" }} ref={scrollBottomRef}></div>
      {/* </div> */}
      <ListItem style={{ paddingLeft: 0, paddingRight: 0 }}>
        <input
          ref={fileInput}
          className="fileinput"
          accept=".jpeg,.png,.jpg,.pdf"
          type="file"
          onChange={(e) => onSendPicture(e)}
        />
        <Button
          justIcon
          round
          onClick={() => fileInput.current.click()}
          style={{ height: 54 }}
          size="large"
          variant="contained"
          color="primary"
        >
          <Image />
        </Button>
        <TextField
          fullWidth
          multiline={false}
          onKeyPress={(ev) => {
            if (ev.key === "Enter") {
              onSend();
              ev.preventDefault();
            }
          }}
          value={text}
          onChange={(e) => setText(e.target.value)}
          id="filled-basic"
          label="Type here"
          variant="filled"
        />
        <Button
          onClick={onSend}
          style={{ height: 54 }}
          size="large"
          variant="contained"
          color="primary"
        >
          Send
        </Button>
      </ListItem>
      {detailsVisible && currentChannel && (
        <ChannelDetails onClose={() => setDetailsVisible(false)} />
      )}
    </div>
  );
};

export default ChatMessage;
