import React from "react";
import { FieldError } from "react-hook-form";
import styled from "styled-components";

type Props = {
  error: FieldError;
};
const ErrorInputMessage = ({ error }: Props) => {
  if (error) {
    return <ErrorMessage>{error.message || "required"}</ErrorMessage>;
  }
  return null;
};

const ErrorMessage = styled.h5`
  color: red;
`;

export default ErrorInputMessage;
