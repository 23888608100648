import { Holidays, Planning } from "../apis/types";
import { State } from "../root-reducer";

const actions = {
  SET_ALL_PLANNING: "SET_ALL_PLANNING",
  SET_PLANNING_DETAILS: "SET_PLANNING_DETAILS",
  SET_SELECTED_PLANNING: "SET_SELECTED_PLANNING",
  SET_ALL_HOLIDAYS: "SET_ALL_HOLIDAYS",
  SET_AGENDA_FORM: "SET_AGENDA_FORM",
  setPlanning: (data: Planning[]) => ({
    type: actions.SET_ALL_PLANNING,
    payload: data,
  }),
  setAgendaForm: (data: any) => ({
    type: actions.SET_AGENDA_FORM,
    payload: data,
  }),
  setPlanningDetails: (data: Planning | null) => ({
    type: actions.SET_PLANNING_DETAILS,
    payload: data,
  }),
  setSelectedPlanning: (data: Planning | null | { id: number }) => ({
    type: actions.SET_SELECTED_PLANNING,
    payload: data,
  }),
  setHolidays: (data: Holidays[] | { id: number }) => ({
    type: actions.SET_ALL_HOLIDAYS,
    payload: data,
  }),
  updatePlanning(payload: Planning | null, planningId?: number) {
    return (dispatch: any, getState: () => State) => {
      const state: State = getState();
      dispatch(actions.setPlanningDetails(payload));
      if (payload) {
        let copyPlanningList = [...state.Planning.planning];
        const index = copyPlanningList.findIndex(
          (item) => item.id === payload.id
        );
        copyPlanningList[index] = payload;
        dispatch(actions.setPlanning([...copyPlanningList]));
      } else {
        dispatch(
          actions.setPlanning(
            state.Planning.planning.filter(
              (item: Planning) => item.id !== planningId
            )
          )
        );
      }
    };
  },
};
export default actions;
