import { Row } from "antd";
import React from "react";
import { Controller, RegisterOptions, useFormContext } from "react-hook-form";
import { RadioGroup, RadioButton } from "../components/uielements/radio";
import ErrorInputMessage from "./ErrorInputMessage";

type Props = {
  name: string;
  label?: string;
  rules?: RegisterOptions;
  style?: any;
  options: any[];
  optionKey?: string;
  optionValue?: string;
  onChange?: (e: any) => void;
};
const RadioGroupController = ({
  name,
  label,
  rules,
  style,
  options,
  optionKey,
  optionValue,
}: Props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const error = errors && errors[name];
  return (
    <div>
      <Row
        style={{
          display: "flex",
          gap: 10,
          marginBottom: 10,
          alignItems: "center",
          ...style,
        }}
      >
        {label && <h4>{label}</h4>}
        <Controller
          rules={rules}
          control={control}
          name={name}
          render={({ field }) => (
            <RadioGroup {...field}>
              {options.map((option) => (
                <RadioButton
                  value={
                    typeof option === "string"
                      ? option
                      : option[optionKey || name]
                  }
                >
                  {typeof option === "string"
                    ? option
                    : option[optionValue || name]}
                </RadioButton>
              ))}
            </RadioGroup>
          )}
        />
      </Row>
      <ErrorInputMessage error={error as any} />
    </div>
  );
};

export default RadioGroupController;
