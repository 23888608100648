import moment from "moment";
import actions from "./actions";
import dayjs from "dayjs";

const initState = {
  planning: [],
  planningDetails: {},
  selectedPlanning: null,
  holidays: [],
  agendaForm: {
    date_range: [dayjs(), dayjs().add(14, "d")],
    invoice_rate_type: "Default Rate",
    activeTab: 1,
  },
};

export default function planningReducer(state = initState, action) {
  switch (action.type) {
    case actions.SET_ALL_PLANNING:
      return {
        ...state,
        planning: action.payload,
      };
    case actions.SET_AGENDA_FORM:
      return {
        ...state,
        agendaForm: action.payload,
      };
    case actions.SET_PLANNING_DETAILS:
      return {
        ...state,
        planningDetails: action.payload,
      };
    case actions.SET_SELECTED_PLANNING:
      return {
        ...state,
        selectedPlanning: action.payload,
      };
    case actions.SET_ALL_HOLIDAYS:
      return {
        ...state,
        holidays: action.payload,
      };
    default:
      return state;
  }
}
