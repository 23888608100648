import axios from "axios";
import moment from "moment";
import { isClient } from "../../constants";
import { axiosWithToken } from "../axiosMiddleware";

export const API_KEY = "AIzaSyALQVMLKyOi9LbwDsRX3Q2t9c3EcAEdH40";
export const getLocations = async (branchId?: string) => {
  try {
    let url = "/api/locations";
    if (isClient) {
      url = `api/locations/own?branch_id=${branchId}`;
    } else {
      if (branchId) {
        url += `?branch_id=${branchId}`;
      }
    }

    const response = await axiosWithToken.get(url);
    return response?.data;
  } catch (e) {
    return null;
  }
};

export async function getCoordinatesAndTimeZone(address: string) {
  try {
    const coords = await axios.get(
      "https://maps.googleapis.com/maps/api/geocode/json?address=" +
        address +
        "&key=" +
        API_KEY
    );

    const latitude = coords.data.results[0].geometry.location.lat;
    const longitude = coords.data.results[0].geometry.location.lng;

    const timezone = await axios.get(
      `https://maps.googleapis.com/maps/api/timezone/json?location=${latitude},${longitude}&key=${API_KEY}&timestamp=${moment().unix()}`
    );

    return { latitude, longitude, timezone: timezone.data.timeZoneId };
  } catch (e) {
    return null;
  }
}
