import React from "react";
import {
  Controller,
  FieldError,
  RegisterOptions,
  useFormContext,
} from "react-hook-form";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import ErrorInputMessage from "./ErrorInputMessage";
import { API_KEY } from "../redux/apis/locations";

type Props = {
  name: string;
  label?: string;
  rules?: RegisterOptions;
  placeholder?: string;
  error?: FieldError;
  style?: any;
  type?: string;
};
const GooglePlacesAutocompleteController = ({
  name,
  error,
  label,
  rules,
  placeholder,
  style,
  ...rest
}: Props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const fieldError = error ? error : errors && errors[name];

  return (
    <div>
      {label && <h4>{label}</h4>}
      <Controller
        rules={rules}
        control={control}
        name={name}
        render={({ field }) => (
          <GooglePlacesAutocomplete
            selectProps={{
              isClearable: false,
              placeholder,
              style,
              ...field,
              ...rest,
            }}
            apiKey={API_KEY}
          />
        )}
      />
      <ErrorInputMessage error={fieldError as any} />
    </div>
  );
};

export default GooglePlacesAutocompleteController;
