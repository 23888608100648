import React from "react";
import styled from "styled-components";
import SignInStyleWrapper from "../Pages/SignIn/SignIn.styles";
import Logo from "@iso/assets/images/cs.png";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../../redux/root-reducer";
import { FormProvider, useForm } from "react-hook-form";
import InputController from "../../form/InputController";
import Button from "../../components/uielements/button";
import { fetchInitialData, SERVICES, update } from "../../redux/apis/global";
import { getDefaultPath } from "../../redux/apis/utils";
import { history } from "../../redux/store";
import authActions from "../../redux/auth/actions";
import { onLogout } from "../Sidebar/Sidebar";

export default function ConfirmAccount() {
  const dispatch = useDispatch();
  const { user } = useSelector((state: State) => state.Auth);
  const methods = useForm();

  const password = methods.watch("password");

  const onConfirm = async (data: { [x: string]: string }) => {
    const response = await update(`${SERVICES.ACCOUNT}/password`, null, data);
    if (response) {
      dispatch(
        authActions.getUser({
          ...user,
          verified: 1,
        })
      );
      fetchInitialData(dispatch, user);
      history.push(getDefaultPath());
    } else {
      alert("Something went wrong!");
    }
  };

  return (
    <SignInStyleWrapper className="isoSignInPage">
      <div className="isoLoginContentWrapper">
        <div className="isoLoginContent">
          <div className="isoLogoWrapper">
            <div
              style={{
                fontSize: 24,
                fontWeight: 300,
                lineHeight: 1,
                textTransform: "uppercase",
                color: "#788195",
              }}
            >
              Hi <b>{user.first_name}</b>, Welcome to Capital Security Portal
            </div>
          </div>
          <div className="isoSignInForm" style={{ alignItems: "center" }}>
            <img
              height={150}
              style={{ objectFit: "contain" }}
              src={Logo}
              alt="CAPITAL SECURITY"
            />
            <FormProvider {...methods}>
              <Wrapper>
                <div
                  style={{
                    fontSize: 20,
                    fontWeight: 300,
                    lineHeight: 1,
                    textTransform: "uppercase",
                    color: "#788195",
                  }}
                >
                  Choose a password that you can remember:
                </div>
                <InputController
                  name="password"
                  rules={{ required: true }}
                  label="New password"
                  type="password"
                />
                <InputController
                  name="password_confirm"
                  rules={{
                    validate: {
                      notValid: (value: any) =>
                        password !== value
                          ? "confirm password is not matching"
                          : true,
                    } as any,
                  }}
                  label="Confirm password"
                  type="password"
                />
                <Button
                  onClick={methods.handleSubmit(onConfirm)}
                  type="primary"
                >
                  Confirm
                </Button>
                <Button onClick={() => onLogout(dispatch)} type="default">
                  Logout
                </Button>
              </Wrapper>
            </FormProvider>
          </div>
        </div>
      </div>
    </SignInStyleWrapper>
  );
}

const Wrapper = styled.form`
  gap: 15px;
  display: grid;
  margin-top: 30px;
  width: 100%;
`;
