import React, { useState } from "react";
import { ButtonSmall, Wrapper } from "./Calendar.styles";
import { PlusOutlined } from "@ant-design/icons";
import NewBranch from "../../admin/clients/NewBranch";
import SelectController from "../../../form/SelectController";
import { useSelector } from "react-redux";
import { State } from "../../../redux/root-reducer";

type Props = {
  clientId?: string | number | undefined;
  extraData?: Array<any>;
  required?: boolean;
  disabled?: boolean;
};
const BranchSelection = ({
  clientId,
  extraData = [],
  required = true,
  disabled = false,
}: Props) => {
  const [visible, setVisible] = useState(false);

  const { branches } = useSelector((state: State) => state.Clients);
  const data = [...extraData, ...branches];

  return (
    <>
      <Wrapper>
        <h4>Select branch {!required ? "(optional)" : ""}</h4>
        {!disabled && (
          <ButtonSmall
            style={{ height: 24 }}
            size="small"
            icon={<PlusOutlined size={5} />}
            onClick={() => setVisible(true)}
            type="primary"
          />
        )}
      </Wrapper>
      <SelectController
        disabled={disabled}
        name="branch_id"
        rules={{ required }}
        options={
          clientId
            ? data.filter((item) => item.client_id + "" == clientId)
            : data
        }
        optionValue="name"
        optionKey="id"
        placeholder="Please select"
      />
      {visible && (
        <NewBranch
          clientId={clientId}
          onClose={() => setVisible(false)}
          visible={visible}
        />
      )}
    </>
  );
};

export default BranchSelection;
