import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import createSagaMiddleware from "redux-saga";
import logger from "redux-logger";
import rootReducer from "./root-reducer";
import rootSaga from "./root-saga";

import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import loadingMiddleware from "./loadingMiddleware";
import { persistStore } from "redux-persist";
import Interceptor from "./interceptor";
import { isDev } from "./apis/utils";
import notificationSaga from "./notifications/saga";
export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();

let middlewares = [];
if (isDev()) {
  middlewares = [
    routerMiddleware(history),
    thunk,
    sagaMiddleware,
    loadingMiddleware,
    logger,
  ];
} else {
  middlewares = [
    routerMiddleware(history),
    thunk,
    sagaMiddleware,
    loadingMiddleware,
  ];
}

const bindMiddleware = (middleware: any) => {
  if (process.env.NODE_ENV !== "production") {
    const { composeWithDevTools } = require("redux-devtools-extension");
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

const store = createStore(rootReducer(history), bindMiddleware(middlewares));
sagaMiddleware.run(rootSaga);
// const persist = persistStore(store, null, () => {});
Interceptor.interceptor(store);

export { store };
