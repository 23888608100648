import { AgentShiftStatus } from "../../../redux/apis/types";

export const getShiftStatusColor = (status: AgentShiftStatus) => {
  switch (status) {
    case AgentShiftStatus.started:
      return "purple";
    case AgentShiftStatus.pending:
      return "red";
    case AgentShiftStatus.confirmed:
      return "green";
    case AgentShiftStatus.ended:
      return "#242b50";
    default:
      return "green";
  }
};
