import { Modal } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { FormProvider, useForm } from "react-hook-form";
import InputController from "../../../form/InputController";
import { BranchRate } from "../../../redux/apis/types";
import { add, SERVICES, update } from "../../../redux/apis/global";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../../../redux/root-reducer";
import actions from "../../../redux/clients/actions";
import LocationSelection from "../../client/NewPlanning/LocationSelection";
import { getLocationCurrency } from "../../../redux/utils";
import SelectController from "../../../form/SelectController";
import currencies from "./currencies";

type Props = {
  visible: boolean;
  data?: BranchRate | undefined;
  disableLocation?: boolean;
  branchId: number | null;
  clientId: number | null;
  locationId?: number | null;
  onClose: (branchRate?: BranchRate) => void;
};

const NewBranchRate = (props: Props) => {
  const { branchRates, locations } = useSelector(
    (state: State) => state.Clients
  );
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const methods = useForm<any>({
    defaultValues: {
      ...props.data,
      branch_id: props.branchId,
      client_id: props.clientId,
      location_id: props.locationId,
      rate: props.data?.rate ? props.data.rate / 100 : null,
      holiday_rate: 100,
    },
  });
  const { handleSubmit, watch, setValue } = methods;

  const locationId = watch("location_id");

  useEffect(() => {
    setValue(
      "currency",
      locationId && !props.data?.id
        ? getLocationCurrency(locationId, locations)
        : props.data?.currency
    );
  }, [locationId]);

  const onSave = async (data: any) => {
    setLoading(true);
    const id = props.data?.id;
    if (id) {
      const resp = await update(`${SERVICES.RATE}/branch`, id, {
        ...data,
        rate: data.rate * 100,
        currency:
          data.currency || getLocationCurrency(data.location_id, locations),
      });
      if (resp) {
        props.onClose(resp);
        let selectorCopy = [...branchRates];
        const index = selectorCopy.findIndex((item) => item.id === id);
        selectorCopy[index] = resp;
        dispatch(actions.setBranchRates([...selectorCopy]));
      }
    } else {
      const request = {
        branch_id: props.branchId,
        client_id: props.branchId,
        ...data,
        currency:
          data.currency || getLocationCurrency(data.location_id, locations),
        rate: data.rate * 100,
      };
      const resp = await add(`${SERVICES.RATE}/branch`, request);
      if (resp) {
        props.onClose(resp);
        dispatch(actions.setBranchRates([...branchRates, resp]));
      }
    }
    setLoading(false);
  };

  const currencyList = useMemo(() => {
    return Object.keys(currencies);
  }, []);

  return (
    <Modal
      title={"Branch Rate"}
      visible={props.visible}
      confirmLoading={loading}
      onOk={handleSubmit(onSave)}
      onCancel={() => props.onClose()}
      okText="Save"
      cancelText="Cancel"
    >
      <FormProvider {...methods}>
        <Wrapper>
          <section>
            <LocationSelection
              readOnly={props.disableLocation}
              disableLocation={props.disableLocation}
              branchId={props.branchId as number}
            />
          </section>
          <InputController
            label="Name"
            name="name"
            rules={{ required: true }}
          />
          <SelectController
            name="currency"
            options={currencyList}
            rules={{ required: false }}
            label="Currency"
          />

          <InputController
            label={`Rate (${
              locationId
                ? getLocationCurrency(locationId, locations)
                : "currency"
            })`}
            type="number"
            name="rate"
            rules={{ required: true }}
          />
          <InputController
            label="Holiday Rate %"
            type="number"
            name="holiday_rate"
            rules={{ required: true }}
          />
        </Wrapper>
      </FormProvider>
    </Modal>
  );
};

const Wrapper = styled.form`
  gap: 15px;
  display: grid;
`;

export default NewBranchRate;
