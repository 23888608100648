import React from "react";
import TableWrapper from "../AntTables.styles";

export default function ({
  dataSource,
  dataList,
  columns,
  rowSelection,
  ...rest
}: any) {
  return (
    <TableWrapper
      rowSelection={rowSelection}
      pagination={false}
      columns={columns}
      dataSource={dataSource}
      className="isoSimpleTable"
      {...rest}
    />
  );
}
