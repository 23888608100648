import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button, Checkbox, ListItem, Typography } from "@material-ui/core";
import { variables } from "../../../assets/styles/variables";
import { DeleteOutline } from "@material-ui/icons";

export const getRole = (member, user) => {
  if (user.id == member.id) return "You";
  if (member.entity == "admin") return "Capital Security";
  if (member.entity == "subcontractor") return "Sub C";
  if (member.agent_id) return "Agent";
};

const AgentList = ({
  selectedMembers,
  onSelectionChanged,
  onRemoveRemember,
}) => {
  const { user } = useSelector((state) => state.Auth);
  const { agents } = useSelector((state) => state.Agents);
  const { currentChannel } = useSelector((state) => state.chat);
  const members = [...new Set(currentChannel.members.map((x) => x.user))];

  return (
    <div className="shift-frame" style={{ marginTop: 20 }}>
      {agents.map((item) => {
        const isNew =
          members.findIndex((user) => user.id === item.user.id) === -1;
        return (
          <ListItem
            button
            onClick={() => isNew && onSelectionChanged(item.user.id)}
            key={item.id}
            style={{ justifyContent: "space-between" }}
          >
            <div>
              <div>
                <Typography style={{ fontSize: 16, color: "#333" }}>
                  {item.first_name} {item.last_name}
                </Typography>
                <Typography style={{ fontSize: 12, color: "#333" }}>
                  {item.user.email}
                </Typography>
              </div>
              <Typography
                style={{ fontSize: 14, color: variables.PRIMARY_COLOR, top: 5 }}
              >
                {item.subcontractor ? item.subcontractor.company_name : ""}
              </Typography>
            </div>
            {isNew ? (
              <div>
                <Checkbox checked={selectedMembers.includes(item.user.id)} />
              </div>
            ) : (
              <DeleteOutline
                style={{ marginRight: 8, color: "red" }}
                onClick={() => onRemoveRemember(item)}
              />
            )}
          </ListItem>
        );
      })}
    </div>
  );
};

export default AgentList;
