import { axiosWithToken } from "../axiosMiddleware";

const service = "/api/clients";

export const getBranchesByClientID = async (id: string) => {
  try {
    const response = await axiosWithToken.get(`${service}/${id}/branches`);
    return response?.data;
  } catch (e) {
    return null;
  }
};

export const getAccountsByClientID = async (id: string) => {
  try {
    const response = await axiosWithToken.get(`${service}/${id}/accounts`);
    return response?.data;
  } catch (e) {
    return null;
  }
};

export const uploadClientLogo = async (id: string, image: string) => {
  try {
    const form = new FormData();
    form.append("image", image);
    const response = await axiosWithToken.post(`${service}/${id}/logo`, form, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response?.data;
  } catch (e) {
    return null;
  }
};
