import React, { useEffect, useMemo, useState } from "react";
import { ButtonSmall, Wrapper } from "./Calendar.styles";
import SelectController from "../../../form/SelectController";
import { SERVICES, get } from "../../../redux/apis/global";
import { BranchRate } from "../../../redux/apis/types";
import { FieldError, useFormContext } from "react-hook-form";
import { getBranchRateCurrency } from "../../../redux/utils";
import NewBranchRate from "../../admin/clients/NewBranchRate";
import { PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { State } from "../../../redux/root-reducer";

type Props = {
  branchId?: string;
  clientId?: string;
  locationId?: number;
  extraData?: Array<any>;
  defaultData?: any;
  required?: boolean;
  disabled?: boolean;
  disableLocation?: boolean;
  name: string;
  entrypoint?: "request" | "branch";
  error?: FieldError;
  label?: string;
};
const BranchRateSelection = ({
  branchId,
  clientId,
  defaultData,
  locationId,
  required = true,
  disabled = false,
  entrypoint = "branch",
  disableLocation,
  name,
  error,
  label,
}: Props) => {
  const { user } = useSelector((state: State) => state.Auth);
  const isManager = user.entity === "manager";
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState<BranchRate[]>([]);
  const { watch, setValue } = useFormContext();
  const branchRateId = watch(name);

  useEffect(() => {
    if (branchRateId && data && data.length > 0) {
      const currency = getBranchRateCurrency(branchRateId, data);
      if (currency) setValue("currency", currency);
    }
  }, [branchRateId, data]);

  useEffect(() => {
    getData();

    async function getData() {
      const resp = await get(`${SERVICES.RATE}/branch`, branchId);

      if (resp) {
        console.log('branche rate from service', resp)
        setData([...resp]);
      }
    }
  }, [disabled, branchId]);

  const filteredData = useMemo(() => {
    return data;
  }, [data, locationId, branchId]);

  const onClose = (branchRate?: BranchRate) => {
    if (branchRate) {
      setData((oldData) => [...oldData, branchRate]);
    }

    setVisible(false);
  };
  return (
    <>
      <Wrapper style={{ marginBottom: 0, justifyContent: "space-between" }}>
        <h4>{label || "Select branch rate"}</h4>
        <ButtonSmall
          style={{ height: 24 }}
          size="small"
          disabled={disabled}
          icon={<PlusOutlined size={5} />}
          onClick={() => setVisible(true)}
          type="primary"
        />
      </Wrapper>
      {isManager ? (
        <SelectController
          disabled={disabled}
          name={name}
          error={error}
          rules={{ required }}
          options={filteredData}
          optionValue="name"
          optionKey="id"
          placeholder="Please select"
        />
      ) : (
        <SelectController
          disabled={disabled}
          name={name}
          error={error}
          rules={{ required }}
          options={filteredData}
          optionValue="name"
          optionValue2="rate"
          format2={(rate: BranchRate) =>
            (rate.rate / 100).toString() + " " + rate?.currency
          }
          separator=" - "
          optionKey="id"
          placeholder="Please select"
        />
      )}

      {visible && (
        <NewBranchRate
          branchId={branchId ? parseInt(branchId) : null}
          clientId={clientId ? parseInt(clientId) : null}
          locationId={locationId ?? null}
          disableLocation={disableLocation}
          data={defaultData}
          onClose={onClose}
          visible={visible}
        />
      )}
    </>
  );
};

export default BranchRateSelection;
