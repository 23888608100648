import { Button } from "antd";
import React, { useEffect, useState } from "react";
import DatePickerController from "../../../form/DatePickerController";
import InputController from "../../../form/InputController";
import SelectController from "../../../form/SelectController";
import { InboxOutlined } from "@ant-design/icons";

import moment, { Moment } from "moment";

import Modal from "../../../components/Feedback/Modal";
import { FormProvider, useForm } from "react-hook-form";
import { add, get, SERVICES, update } from "../../../redux/apis/global";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../../../redux/root-reducer";
import actions from "../../../redux/planning/actions";
import {
  generateHistoryRequest,
  timeZoneMoment,
} from "../../../redux/apis/utils";
import { PlanningHistory, Shift } from "../../../redux/apis/types";
import TextAreaController from "../../../form/TextAreaController";
import { message, Upload } from "antd";
import RemoteImage from "./RemoteImage";
import { BASE_PATH } from "../../../redux/axiosMiddleware";
import { UploadFile } from "antd/lib/upload/interface";
import { uploadImageHistory } from "../../../redux/apis/planning";
import { LocalConvenienceStoreOutlined } from "@material-ui/icons";

const { Dragger } = Upload;

export type FormShift = {
  start_date: Moment;
  end_date: Moment;
  start_time: string;
  end_time: string;
  agents?: Array<number>;
  subcontractor_id?: number;
};
type Props = {
  visible: boolean;
  onClose: () => void;
  data?: PlanningHistory | undefined;
};
const NewHistoryModal = ({ visible, onClose, ...rest }: Props) => {
  let fileInput = React.createRef<HTMLInputElement>();
  const [fileLogo, setFileLogo] = useState();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { planningDetails } = useSelector((state: State) => state.Planning);
  const { agents: agentsSelector } = useSelector(
    (state: State) => state.Agents
  );

  const [imagePreviewUrl, setImagePreviewUrl] = React.useState<
    string | undefined
  >();

  const handleImageChange = (e: any) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setFileLogo(file);
      // setValue("logo", file);
      // props.onDataChanged("file", file);
      setImagePreviewUrl(reader.result?.toString());
    };
    reader.readAsDataURL(file);
  };

  const methods = useForm<any>({
    defaultValues: rest.data
      ? {
          ...rest.data,
          created_at: moment(rest.data.created_at),
          break_finished_at: rest.data.break_finished_at
            ? moment(rest.data.break_finished_at)
            : rest.data.break_finished_at,
        }
      : {
          request_id: planningDetails.request_id,
          planning_id: planningDetails.id,
          created_at: moment(),
        },
  });

  const { handleSubmit, watch, setValue } = methods;

  const status = watch("status");
  const shift_id = watch("shift_id");

  const onSave = async (data: any) => {
    setLoading(true);
    const id = rest.data?.id;
    if (id) {
      const history = await update(
        SERVICES.PLANNING_HISTORY,
        id,
        generateHistoryRequest(data, planningDetails)
      );
      if (history) {
        const copy = Object.assign({}, planningDetails);
        const index = planningDetails.history.findIndex(
          (history) => history.id === id
        );
        copy.history[index] = Object.assign({}, history);
        copy.history = [...copy.history];
        dispatch(actions.setPlanningDetails(copy));
        onClose();
      }
    } else {
      if (data.status === "started" || data.status === "ended") {
        const status = await update(
          `${SERVICES.SHIFT}/${data.shift_id}/status`,
          null,
          generateHistoryRequest(data, planningDetails)
        );

        if (status) {
          const data = await get(SERVICES.PLANNING, planningDetails.id);
          dispatch(actions.setPlanningDetails(data));
          onClose();
        }
      } else {
        const history = await add(
          SERVICES.PLANNING_HISTORY,
          generateHistoryRequest(data, planningDetails)
        );
        if (history) {
          if (fileLogo) {
            const imageHistory = await uploadImageHistory(
              history.id,
              fileLogo,
              data.agent_id
              // `data:${noteData.image.type};base64,${noteData.image.data}`,
            );
            if (imageHistory) {
              history.image = imageHistory.image;
            }
          }
          const copy = Object.assign({}, planningDetails);
          copy.history = [...copy.history, history];
          dispatch(actions.setPlanningDetails(copy));

          onClose();
        }
      }
    }
    setLoading(false);
  };

  console.log("visiblevisible : ", visible);
  return (
    <Modal
      title={"History Details"}
      open={visible}
      onOk={handleSubmit(onSave)}
      onCancel={onClose}
      okText="Save"
      confirmLoading={loading}
      cancelText="Cancel"
    >
      <FormProvider {...methods}>
        <section>
          <SelectController
            name="shift_id"
            label="Shift"
            rules={{ required: true }}
            options={planningDetails.shifts}
            optionKey="id"
            optionValue="start"
            optionValue2="end"
            separator=" / "
            format={(value: string) =>
              timeZoneMoment(value, planningDetails.location?.timezone).format(
                "DD-MM-YYYY HH:mm"
              )
            }
            format2={(shift: Shift) => {
              return `${timeZoneMoment(
                shift.end,
                planningDetails.location?.timezone
              ).format("DD-MM-YYYY HH:mm")} ${shift.agents
                .map((agent) => agent.first_name)
                .join(", ")}`;
            }}
            placeholder="Select shift"
          />
        </section>

        {shift_id && (
          <section>
            <SelectController
              name="agent_id"
              label="Agent"
              optionKey="id"
              optionValue="first_name"
              optionValue2="last_name"
              rules={{ required: true }}
              options={
                planningDetails?.shifts?.find((shift) => shift.id === shift_id)
                  ?.agents || []
              }
              placeholder="Please select"
            />
          </section>
        )}

        {!rest.data?.id && (
          <section>
            <SelectController
              name="status"
              label="Status"
              rules={{ required: true }}
              options={["started", "ended", "image", "break", "note"]}
              placeholder="Please select"
            />
          </section>
        )}

        <section>
          <DatePickerController
            rules={{ required: true }}
            name={`created_at`}
            showTime={{ format: "HH:mm" }}
            format="DD/MM/YYYY HH:mm"
            label="Created At"
            style={{ width: "100%" }}
          />
        </section>
        {status === "break" && (
          <section>
            <DatePickerController
              name={`break_finished_at`}
              showTime={{ format: "HH:mm" }}
              format="DD/MM/YYYY HH:mm"
              label="Break finished At"
              style={{ width: "100%" }}
            />
          </section>
        )}

        <section>
          {status === "break" ? (
            <TextAreaController
              label="Break reason"
              name="break_reason"
              placeholder="break reason"
            />
          ) : (
            <TextAreaController label="Note" name="note" placeholder="note" />
          )}
        </section>
        {status === "image" && (
          <section>
            <input
              style={{ display: "none" }}
              type="file"
              onChange={handleImageChange}
              ref={fileInput}
            />
            {!rest.data?.id && (
              <div>
                <img
                  style={{
                    objectFit: "contain",
                    marginTop: 20,
                    marginBottom: 20,
                  }}
                  height={200}
                  width={200}
                  src={
                    imagePreviewUrl ||
                    "https://reactnativecode.com/wp-content/uploads/2018/02/Default_Image_Thumbnail.png"
                  }
                  alt="..."
                />
                <div>
                  <Button
                    onClick={() => fileInput.current?.click()}
                    type="link"
                  >
                    Choose picture
                  </Button>
                </div>
              </div>
            )}
          </section>
        )}
      </FormProvider>
    </Modal>
  );
};

export default NewHistoryModal;
