import { Button, Modal } from "antd";
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import InputController from "../../../form/InputController";
import { uploadClientLogo } from "../../../redux/apis/clients";
import { add, SERVICES, update } from "../../../redux/apis/global";
import { Client } from "../../../redux/apis/types";
import { BASE_PATH } from "../../../redux/axiosMiddleware";
import actions from "../../../redux/clients/actions";

type Props = {
  visible: boolean;
  logo?: string;
  data?: Client | undefined;
  onClose: () => void;
};

const NewClient = (props: Props) => {
  const { clients } = useSelector((state: any) => state.Clients);
  const [fileLogo, setFileLogo] = useState();
  const dispatch = useDispatch();
  const methods = useForm({
    defaultValues: props.data,
  });
  const [loading, setLoading] = useState(false);
  const { handleSubmit } = methods;
  let fileInput = React.createRef<HTMLInputElement>();
  const [imagePreviewUrl, setImagePreviewUrl] = React.useState<
    string | undefined
  >(
    props.data?.logo
      ? `${BASE_PATH}/images/logos/${props.data?.logo}`
      : "https://reactnativecode.com/wp-content/uploads/2018/02/Default_Image_Thumbnail.png"
  );
  const handleImageChange = (e: any) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setFileLogo(file);
      // setValue("logo", file);
      // props.onDataChanged("file", file);
      setImagePreviewUrl(reader.result?.toString());
    };
    reader.readAsDataURL(file);
  };

  const onSave = async (data: any) => {
    setLoading(true);
    const id = props.data?.id;
    if (id) {
      const client = await update(SERVICES.CLIENT, id, data);
      if (client) {
        let clientCopy = [...clients];
        const index = clientCopy.findIndex((client) => client.id === id);
        clientCopy[index] = client;
        dispatch(actions.setClients([...clientCopy]));
        if (fileLogo) {
          const logo = await uploadClientLogo(client.id, fileLogo);
          props.onClose();
          if (logo && logo.name) {
            client.logo = logo.name;
            clientCopy[index] = client;
            dispatch(actions.setClients([...clientCopy]));
          } else {
            console.log("can't upload image");
          }
        } else {
          props.onClose();
        }
      }
    } else {
      const client = await add(SERVICES.CLIENT, data);
      if (client) {
        dispatch(actions.setClients([...clients, client]));
        if (fileLogo) {
          const logo = await uploadClientLogo(client.id, fileLogo);
          props.onClose();
          if (logo && logo.name) {
            let clientCopy = [...clients, client];
            const index = clientCopy.findIndex((client) => client.id === id);
            client.logo = logo.name;
            clientCopy[index] = client;
            dispatch(actions.setClients([...clientCopy]));
            props.onClose();
          }
        } else {
          props.onClose();
        }
      }
    }
    setLoading(false);
  };
  return (
    <Modal
      title={"New Client"}
      confirmLoading={loading}
      visible={props.visible}
      onOk={handleSubmit(onSave)}
      onCancel={props.onClose}
      okText="Save"
      cancelText="Cancel"
    >
      <FormProvider {...methods}>
        <InputController
          rules={{ required: true }}
          name="name"
          label="Client name"
          placeholder="Name"
        />
        <input
          style={{ display: "none" }}
          type="file"
          onChange={handleImageChange}
          ref={fileInput}
        />

        <img
          style={{ objectFit: "contain", marginTop: 20, marginBottom: 20 }}
          height={200}
          width={200}
          src={imagePreviewUrl}
          alt="..."
        />
        <div>
          <Button onClick={() => fileInput.current?.click()} type="link">
            Choose picture
          </Button>
        </div>
      </FormProvider>
    </Modal>
  );
};

export default NewClient;
