import React from "react";
import moment from "moment";
import { TableViews } from "../../Tables/AntTables/AntTables";
import Modal from "../../../components/Feedback/Modal";
import { FormProvider, useForm } from "react-hook-form";

type Props = {
  visible: boolean;
  onClose: () => void;
};

const columns = [
  {
    title: "Start Date",
    dataIndex: "start",
  },
  {
    title: "End Date",
    dataIndex: "end",
  },
];

const ConfirmPlanningModal = ({ visible, onClose }: Props) => {
  const methods = useForm({
    defaultValues: {
      date_range: [moment(), moment()],
      is24HourSecurity: "NO",
      startDate: moment(),
      slots: [{ id: 1 }],
    },
  });
  const { handleSubmit } = methods;

  const onSave = (data: any) => {};

  const rowSelection = {
    type: "checkbox",
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {},
    getCheckboxProps: (record: any) => ({
      disabled: false,
      name: record.start,
    }),
  };

  return (
    <Modal
      title={"Confirm Planning"}
      visible={visible}
      onOk={handleSubmit(onSave)}
      onCancel={onClose}
      okText="Confirm"
      cancelText="Cancel"
    >
      <FormProvider {...methods}>
        <section style={{ marginBottom: 15 }}>
          <TableViews.SimpleView
            dataSource={[
              {
                start: "27/04/2022 10:00",
                end: "27/04/2022 18:00",
              },
            ]}
            columns={columns}
          />
        </section>
        <h4>
          You have 3 more requests to confirm with the same planning in{" "}
          <b>Footlocker Zandaam</b>
        </h4>
        <section style={{ marginTop: 15 }}>
          <TableViews.SimpleView
            rowSelection={rowSelection}
            dataSource={[
              {
                key: 1,
                start: "27/04/2022 10:00",
                end: "27/04/2022 18:00",
              },
              {
                key: 2,
                start: "27/04/2022 10:00",
                end: "27/04/2022 18:00",
              },
              {
                key: 3,
                start: "27/04/2022 10:00",
                end: "27/04/2022 18:00",
              },
            ]}
            columns={columns}
          />
        </section>
      </FormProvider>
    </Modal>
  );
};

export default ConfirmPlanningModal;
