import React from "react";
import {
  Controller,
  FieldError,
  RegisterOptions,
  useFormContext,
} from "react-hook-form";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import Button from "../components/uielements/button";

type Props = {
  name: string;
  label?: string;
  rules?: RegisterOptions;
  error?: FieldError;
  style?: any;
  type?: string;
  onChange?: (action: any) => void;
};

const ActionButtonsController = ({ name, rules, style, onChange }: Props) => {
  const { control, watch } = useFormContext();

  const action = watch(name);

  return (
    <Controller
      rules={rules}
      control={control}
      name={name}
      render={({ field }) => (
        <div style={{ display: "flex", gap: 10, ...style }}>
          <Button
            onClick={() => {
              field.onChange("confirmed");
              onChange?.("confirmed");
            }}
            type={action === "confirmed" ? "success" : "default"}
            icon={<CheckOutlined />}
            shape="circle"
          />
          <Button
            onClick={() => {
              field.onChange("rejected");
              onChange?.("rejected");
            }}
            type={action === "rejected" ? "danger" : "default"}
            icon={<CloseOutlined />}
            shape="circle"
          />
        </div>
      )}
    />
  );
};

export default ActionButtonsController;
