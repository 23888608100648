export const MANAGER_OPTIONS = [
  {
    key: "agenda",
    label: "Agenda",
    leftIcon: "ion-calendar",
  },
  // {
  //   key: "requests",
  //   label: "Requests",
  //   leftIcon: "ion-calendar",
  // },
  // {
  //   key: "planning",
  //   label: "Planning",
  //   leftIcon: "ion-calendar",
  // },
  // {
  //   key: "shifts",
  //   label: "Shift Management",
  //   leftIcon: "ion-ios-home",
  // },
  {
    key: "clients",
    label: "Clients",
    leftIcon: "ion-bag",
  },
  {
    key: "locations",
    label: "Locations",
    leftIcon: "ion-ios-home",
  },
  {
    key: "subcontractors",
    label: "Subcontractors",
    leftIcon: "ion-briefcase",
  },
  {
    key: "agents",
    label: "Agents",
    leftIcon: "ion-ios-body",
  },
  {
    key: "holidays",
    label: "Holidays",
    leftIcon: "ion-log-out",
  },
  {
    key: "logout",
    label: "Log out",
    leftIcon: "ion-log-out",
  },
];

export const ADMIN_OPTIONS = [
  {
    key: "agenda",
    label: "Agenda",
    leftIcon: "ion-calendar",
  },
  // {
  //   key: "requests",
  //   label: "Requests",
  //   leftIcon: "ion-calendar",
  // },
  // {
  //   key: "planning",
  //   label: "Planning",
  //   leftIcon: "ion-calendar",
  // },
  // {
  //   key: "shifts",
  //   label: "Shift Management",
  //   leftIcon: "ion-ios-home",
  // },
  {
    key: "clients",
    label: "Clients",
    leftIcon: "ion-bag",
  },
  {
    key: "locations",
    label: "Locations",
    leftIcon: "ion-ios-home",
  },
  {
    key: "subcontractors",
    label: "Subcontractors",
    leftIcon: "ion-briefcase",
  },
  {
    key: "agents",
    label: "Agents",
    leftIcon: "ion-ios-body",
  },
  {
    key: "holidays",
    label: "Holidays",
    leftIcon: "ion-log-out",
  },
  {
    key: "invoices",
    label: "Invoices",
    leftIcon: "ion-log-out",
  },
  {
    key: "logout",
    label: "Log out",
    leftIcon: "ion-log-out",
  },
];
export const SUBC_OPTIONS = [
  {
    key: "agenda",
    label: "Agenda",
    leftIcon: "ion-calendar",
  },
  // {
  //   key: "planning",
  //   label: "Planning",
  //   leftIcon: "ion-calendar",
  // },
  // {
  //   key: "shifts",
  //   label: "Shift Management",
  //   leftIcon: "ion-ios-home",
  // },
  {
    key: "agents",
    label: "Agents",
    leftIcon: "ion-ios-body",
  },
  {
    key: "logout",
    label: "Log out",
    leftIcon: "ion-log-out",
  },
];

export const CLIENT_OPTIONS = [
  {
    key: "agenda",
    label: "Agenda",
    leftIcon: "ion-calendar",
  },
  // {
  //   key: "requests",
  //   label: "Requests",
  //   leftIcon: "ion-calendar",
  // },
  // {
  //   key: "planning",
  //   label: "Planning",
  //   leftIcon: "ion-calendar",
  // },
  {
    key: "branches",
    label: "Branches",
    leftIcon: "ion-calendar",
  },
  // {
  //   key: "locations",
  //   label: "Locations",
  //   leftIcon: "ion-ios-home",
  // },
  {
    key: "logout",
    label: "Log out",
    leftIcon: "ion-log-out",
  },
];

export const BRANCH_OPTIONS = [
  {
    key: "agenda",
    label: "Agenda",
    leftIcon: "ion-calendar",
  },
  // {
  //   key: "requests",
  //   label: "Requests",
  //   leftIcon: "ion-calendar",
  // },
  // {
  //   key: "planning",
  //   label: "Planning",
  //   leftIcon: "ion-calendar",
  // },
  {
    key: "locations",
    label: "Locations",
    leftIcon: "ion-ios-home",
  },
  {
    key: "logout",
    label: "Log out",
    leftIcon: "ion-log-out",
  },
];
