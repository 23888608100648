import React from "react";
import { Link } from "react-router-dom";
import siteConfig from "@iso/config/site.config";
import Logo from "@iso/assets/images/cs.png";

export default ({ collapsed }) => {
  return (
    <div className="isoLogoWrapper">
      {collapsed ? (
        <div>
          <h3>
            <Link to="/dashboard">
              <img
                height={50}
                style={{ objectFit: "contain" }}
                src={Logo}
                alt="CAPITAL SECURITY"
              />
            </Link>
          </h3>
        </div>
      ) : (
        <h3>
          <Link to="/dashboard">
            <img
              height={100}
              style={{ objectFit: "contain" }}
              src={Logo}
              alt="CAPITAL SECURITY"
            />
          </Link>
        </h3>
      )}
    </div>
  );
};
