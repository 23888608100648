import actions from "./actions";

const initState = {
  clients: [],
  branches: [],
  accounts: [],
  locations: [],
  contacts: [],
  branchRates: [],
};

export default function clientReducer(state = initState, action) {
  switch (action.type) {
    case actions.SET_ALL_CLIENTS:
      return {
        ...state,
        clients: action.payload,
      };
    case actions.SET_ALL_BRANCHES:
      return {
        ...state,
        branches: action.payload,
      };
    case actions.SET_ALL_CLIENT_ACCOUNTS:
      return {
        ...state,
        accounts: action.payload,
      };
    case actions.SET_LOCATIONS:
      return {
        ...state,
        locations: action.payload,
      };
    case actions.SET_CONTACTS:
      return {
        ...state,
        contacts: action.payload,
      };
    case actions.SET_BRANCH_RATES:
      return {
        ...state,
        branchRates: action.payload,
      };
    default:
      return state;
  }
}
