import { BASE_PATH } from "../../../redux/axiosMiddleware";

import ClientOAuth2 from "client-oauth2";

export const OAUTH_CLIENT_ID = "1";

export const REDIRECT_URI = `${window.location.protocol}//${window.location.host}/auth`;

export const REDIRECT_LOGOUt_URI = `${window.location.protocol}//${window.location.host}/signin`;

export const OAUTH_CLIENT = new ClientOAuth2({
  clientId: "1",
  clientSecret: "N/A",
  accessTokenUri: `${BASE_PATH}/oauth/token`,
  authorizationUri: `${BASE_PATH}/oauth/authorize`,
  redirectUri: REDIRECT_URI,
  scopes: ["*"],
});
