import React, { useEffect, useMemo, useState } from "react";
import { TableViews } from "../../Tables/AntTables/AntTables";
import Modal from "../../../components/Feedback/Modal";
import { FormProvider, useForm } from "react-hook-form";
import SelectController from "../../../form/SelectController";
import { Shift } from "../../../redux/apis/types";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../../../redux/root-reducer";
import { get, SERVICES, update } from "../../../redux/apis/global";
import actions from "../../../redux/planning/actions";
import { Button, Typography } from "antd";
import { timeZoneMoment } from "../../../redux/apis/utils";

type Props = {
  visible: boolean;
  onClose: () => void;
};

type DataForm = {
  shift_id: number;
  subcontractor_id: number | null;
};

const ForwardShiftModal = ({ visible, onClose }: Props) => {
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { contractors } = useSelector((state: State) => state.Contractors);
  const { planningDetails } = useSelector((state: State) => state.Planning);

  const nonAssignedShifts = useMemo(() => {
    return (
      planningDetails?.shifts?.filter(
        (item) =>
          !item.subcontractor_id || item.subcontractor_status === "rejected"
      ) || []
    );
  }, [planningDetails?.shifts]);

  const methods = useForm({
    defaultValues: {
      subcontractor_id: null,
      slots: [] as Array<DataForm>,
    },
  });

  const {
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    setValue,
    watch,
  } = methods;

  useEffect(() => {
    if (nonAssignedShifts) {
      reset({
        ...getValues(),
        slots: nonAssignedShifts.map((item: Shift) => {
          return {
            shift_id: item.id,
            subcontractor_id: item.subcontractor_id,
          };
        }),
      });
    }
  }, [nonAssignedShifts]);

  const relatedColumns = [
    {
      title: "Start Date",
      render: (text: string, record: Shift) => (
        <Typography.Text>
          {timeZoneMoment(
            record.start,
            planningDetails.location?.timezone
          ).format("DD-MM-YYYY HH:mm")}
        </Typography.Text>
      ),
    },
    {
      title: "End Date",
      render: (text: string, record: Shift) => (
        <Typography.Text>
          {timeZoneMoment(
            record.end,
            planningDetails.location?.timezone
          ).format("DD-MM-YYYY HH:mm")}
        </Typography.Text>
      ),
    },
    {
      title: "Subcontractor",
      render: (text: string, record: any, index: number) => (
        <SelectController
          rules={{ required: false }}
          name={`slots.${index}.subcontractor_id`}
          label={undefined}
          options={contractors}
          placeholder="subcontractor"
          optionKey="id"
          optionValue="name"
        />
      ),
    },
  ];

  const onSave = async (data: { slots: Array<DataForm> }) => {
    setLoading(true);
    const resp = await update(`${SERVICES.SHIFT}/forward`, null, {
      forwarded: data.slots.filter((slot: DataForm) => !!slot.subcontractor_id),
    });
    if (resp) {
      const planning = await get(SERVICES.PLANNING, planningDetails.id);
      if (planning) dispatch(actions.updatePlanning(planning));
      onClose();
    }

    setLoading(false);
  };

  const slots = watch("slots");
  const subcontractorId = watch("subcontractor_id");

  useEffect(() => {
    if (subcontractorId) {
      forwardAll();
    }
  }, [subcontractorId]);

  const forwardAll = () => {
    const slotsCopy = [...slots];
    for (let i = 0; i < slotsCopy.length; i++) {
      slotsCopy[i].subcontractor_id = subcontractorId;
    }
    setValue("slots", slotsCopy);
  };

  return (
    <Modal
      title={"Forward Shift(s)"}
      visible={visible}
      onOk={handleSubmit(onSave)}
      onCancel={onClose}
      confirmLoading={isLoading}
      okText="Forward"
      cancelText="Cancel"
      footer={[
        <Button key="back" onClick={onClose}>
          Cancel
        </Button>,
        <Button
          key="submit"
          disabled={!methods.formState.isDirty}
          type="primary"
          loading={isLoading}
          onClick={handleSubmit(onSave)}
        >
          Submit
        </Button>,
      ]}
    >
      <FormProvider {...methods}>
        <div>
          <Typography>Select All</Typography>
          <SelectController
            disabled={nonAssignedShifts?.length === 0}
            rules={{ required: false }}
            name={`subcontractor_id`}
            label={undefined}
            options={contractors}
            placeholder="subcontractor"
            optionKey="id"
            optionValue="name"
          />
        </div>

        <section style={{ marginTop: 15 }}>
          <TableViews.SimpleView
            dataSource={nonAssignedShifts}
            columns={relatedColumns}
          />
        </section>
      </FormProvider>
    </Modal>
  );
};

export default ForwardShiftModal;
