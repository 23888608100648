import { Modal } from "antd";
import React, { useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import InputController from "../../../form/InputController";
import SelectController from "../../../form/SelectController";
import { add, SERVICES, update } from "../../../redux/apis/global";
import { Branch } from "../../../redux/apis/types";
import actions from "../../../redux/clients/actions";
import { State } from "../../../redux/root-reducer";
import ClientSelection from "../../client/NewPlanning/ClientSelection";
import countries from "./countries";

export type Data = Omit<Branch, "id">;

type Props = {
  visible: boolean;
  data?: Branch | undefined;
  clientId?: string | number | undefined;
  onClose: () => void;
};

const NewBranch = (props: Props) => {
  const { branches } = useSelector((state: State) => state.Clients);
  const countriesLabel = useMemo(
    () => countries.map((item) => item.CountryName),
    []
  );
  const dispatch = useDispatch();
  const methods = useForm({
    defaultValues: props.data
      ? props.data
      : {
          country: "Netherlands",
        },
  });

  // console.log("error : ", methods.formState.errors);
  const [loading, setLoading] = useState(false);

  const onSave = async (data: any) => {
    // console.log("data.country : ", data.country);
    const country = countries.find((item) => item.CountryName == data.country);
    if (!country) return;
    setLoading(true);
    const id = props.data?.id;
    if (id) {
      data.country = country.CountryName;
      const branch = await update(SERVICES.BRANCH, id, data);
      if (branch) {
        let branchCopy = [...branches];
        const index = branchCopy.findIndex((branch) => branch.id === id);
        branchCopy[index] = branch;
        dispatch(actions.setBranches([...branchCopy]));
        props.onClose();
      }
    } else {
      const branch = await add(SERVICES.BRANCH, {
        ...data,
        client_id: props.clientId || data.client_id,
        country: country.CountryName,
      });
      if (branch) {
        dispatch(actions.setBranches([...branches, branch]));
        props.onClose();
      }
    }
    setLoading(false);
  };

  const { handleSubmit } = methods;

  return (
    <Modal
      title={"Branch"}
      visible={props.visible}
      confirmLoading={loading}
      onOk={handleSubmit(onSave)}
      onCancel={props.onClose}
      okText="Save"
      cancelText="Cancel"
    >
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(onSave)}
          style={{ rowGap: 10, display: "grid" }}
        >
          {!props.clientId && <ClientSelection />}
          <InputController
            rules={{ required: true }}
            name="name"
            label="Company name"
          />
          <InputController
            rules={{ required: true }}
            name="company_registration_number"
            label="Company registration number"
          />
          <InputController
            rules={{ required: true }}
            name="vat_number"
            label="VAT number"
          />
          <InputController
            rules={{ required: true }}
            name="vat_rate"
            type="number"
            label="VAT %"
          />
          <SelectController
            name="country"
            label="Select Country"
            rules={{ required: true }}
            options={countriesLabel}
            valueType="json"
            placeholder="Please select"
          />
          <InputController
            rules={{ required: true }}
            name="billing_address"
            label="Billing Address"
          />
          <InputController
            rules={{ required: true }}
            name="billing_address_2"
            label="Billing Address 2"
            placeholder="city and postcode"
          />
          <InputController
            rules={{ required: false }}
            name="iban"
            label="IBAN Number"
          />
        </form>
      </FormProvider>
    </Modal>
  );
};

export default NewBranch;
