import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
} from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { chatActions } from "../../../redux/chat/actions";
import AgentList from "./AgentList";

const AddMember = ({ onClose }) => {
  const [selectedMembers, setSelectedMembers] = useState([]);
  const dispatch = useDispatch();
  const { currentChannel } = useSelector((state) => state.chat);

  const onSelectionChanged = (userId) => {
    if (selectedMembers.includes(userId)) {
      setSelectedMembers(selectedMembers.filter((x) => x !== userId));
    } else {
      setSelectedMembers([...selectedMembers, userId]);
    }
  };

  const onSave = () => {
    dispatch(
      chatActions.addNewChannelMembers({
        members: selectedMembers,
        channelId: currentChannel.channel_id,
      })
    );
    onClose();
  };
  const onRemoveRemember = (item) => {
    console.log("item : ", item);

    dispatch(
      chatActions.removeChannelMember({
        userId: item.user.id,
        channelId: currentChannel.channel_id,
      })
    );
    onClose();
  };

  return (
    <div
      style={{
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: "#fff",
        zIndex: 999999999999999,
      }}
    >
      <AppBar position="static">
        <Toolbar>
          <IconButton
            edge="start"
            onClick={onClose}
            color="inherit"
            aria-label="menu"
          >
            <ChevronLeft />
          </IconButton>
          <Typography
            variant="h6"
            color="white"
            style={{ flexGrow: 1, color: " white" }}
          >
            New Members ({selectedMembers.length})
          </Typography>
          <Button
            onClick={onSave}
            disabled={selectedMembers.length === 0}
            variant="contained"
            color="secondary"
          >
            save
          </Button>
        </Toolbar>
      </AppBar>
      <AgentList
        onRemoveRemember={onRemoveRemember}
        onSelectionChanged={onSelectionChanged}
        selectedMembers={selectedMembers}
      />
    </div>
  );
};

export default AddMember;
