import React from "react";
import { Controller, RegisterOptions, useFormContext } from "react-hook-form";
import ErrorInputMessage from "./ErrorInputMessage";
import dayjs, { Dayjs } from "dayjs";
import { DatePicker } from "antd";
type Props = {
  name: string;
  label?: string;
  rules?: RegisterOptions;
  placeholder?: string;
  style?: any;
  disabledDate?: any;
  onRangeDateChange?: (ranges: any) => void;
};
const RangePickerController = ({
  name,
  label,
  rules,
  placeholder,
  style,
  onRangeDateChange,
  ...rest
}: Props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const error = errors && errors[name];
  const { RangePicker } = DatePicker;

  return (
    <div>
      {label && <h4>{label}</h4>}
      <Controller
        rules={rules}
        control={control}
        name={name}
        render={({ field: { onChange, ...restField } }) => (
          <div>
            <RangePicker
              // needConfirm
              // defaultValue={[dayjs(), dayjs().add(7, "days")]}
              presets={[
                {
                  label: "This month",
                  value: [dayjs().startOf("month"), dayjs().endOf("month")],
                },
                {
                  label: "This Week",
                  value: [dayjs().startOf("week"), dayjs().endOf("week")],
                },
              ]}
              showTime={false}
              format="DD/MM/YYYY"
              style={style}
              onChange={(ranges) => {
                onChange(ranges);
                onRangeDateChange?.(ranges);
              }}
              // value={[dayjs[(field.value[0], dayjs(field.value[1]))]]}
              // value={field.value}
              {...restField}
              {...rest}
            />
          </div>
        )}
      />
      <ErrorInputMessage error={error as any} />
    </div>
  );
};

export default RangePickerController;
