import React, { useState } from "react";
import { ButtonSmall, Wrapper } from "./Calendar.styles";
import { PlusOutlined } from "@ant-design/icons";
import NewLocation from "../../admin/clients/NewLocation";
import SelectController from "../../../form/SelectController";
import { useSelector } from "react-redux";
import { State } from "../../../redux/root-reducer";
import { Location } from "../../../redux/apis/types";

type Props = {
  branchId: number | null;
  name?: string;
  readOnly?: boolean;
  disableLocation?: boolean;
};
const LocationSelection = ({
  branchId,
  name,
  readOnly,
  disableLocation,
}: Props) => {
  const [visible, setVisible] = useState(false);
  const { locations } = useSelector((state: State) => state.Clients);
  return (
    <>
      <Wrapper>
        <h4>Select location</h4>
        {!readOnly && (
          <ButtonSmall
            style={{ height: 24 }}
            size="small"
            icon={<PlusOutlined />}
            onClick={() => setVisible(true)}
            type="primary"
          />
        )}
      </Wrapper>
      <SelectController
        disabled={disableLocation}
        rules={{ required: true }}
        name={name || "location_id"}
        options={
          branchId
            ? locations.filter(
                (item: Location) =>
                  item.branch_id == branchId + "" || !item.branch_id
              )
            : locations
        }
        optionValue="name"
        // optionValue2="number"
        optionKey="id"
        placeholder="Please select"
      />

      {visible && (
        <NewLocation
          branchId={branchId}
          onClose={() => setVisible(false)}
          visible={visible}
        />
      )}
    </>
  );
};

export default LocationSelection;
