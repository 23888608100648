import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { FormProvider, useForm } from "react-hook-form";
import InputController from "../../../form/InputController";
import GooglePlacesAutocompleteController from "../../../form/GooglePlacesAutocompleteController";
import { Location } from "../../../redux/apis/types";
import { add, SERVICES, update } from "../../../redux/apis/global";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../../../redux/root-reducer";
import actions from "../../../redux/clients/actions";
import SelectController from "../../../form/SelectController";
import { getCoordinatesAndTimeZone } from "../../../redux/apis/locations";
import BranchSelection from "../../client/NewPlanning/BranchSelection";
import countries from "./countries";
import CheckBoxController from "../../../form/CheckBoxController";
type Props = {
  visible: boolean;
  data?: Location | undefined;
  branchId: number | null | string;
  onClose: () => void;
};

const NewLocation = (props: Props) => {
  const { locations } = useSelector((state: State) => state.Clients);
  const { user } = useSelector((state: State) => state.Auth);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const methods = useForm<any>({
    defaultValues: {
      ...props.data,
      branch_id: user?.entity === "branch" ? user?.branch_id : props.branchId,
      country: props.data?.id
        ? JSON.stringify(
            countries.find(
              (country) => country.CountryName === props.data?.country
            )
          )
        : undefined,
    },
  });

  const {
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = methods;

  const address = watch("address");

  useEffect(() => {
    const getCoordsByAddress = async () => {
      const coords = await getCoordinatesAndTimeZone(address.label);
      if (coords?.longitude && coords?.latitude) {
        setValue("longitude", coords.longitude);
        setValue("latitude", coords.latitude);
        setValue("timezone", coords.timezone);
      }
    };
    if (address?.label) {
      getCoordsByAddress();
    }
  });

  const onSave = async (data: any) => {
    const country = JSON.parse(data.country);
    // const coords = await getCoordinatesAndTimeZone(data.address.label);
    setLoading(true);
    const id = props.data?.id;
    if (id) {
      const location = await update(SERVICES.LOCATION, id, {
        ...data,
        address: data.address.label,
        country: country.CountryName,
        // ...coords,
      });

      if (location?.error) {
        // console.log("location : ", location);
        alert(location?.error);
        setLoading(false);
        return;
      } else if (location) {
        let locationCopy = [...locations];
        const index = locationCopy.findIndex((location) => location.id === id);
        locationCopy[index] = location;
        dispatch(actions.setLocations([...locationCopy]));
      }
    } else {
      const request = {
        branch_id: user?.entity === "branch" ? user?.branch_id : props.branchId,
        ...data,
        address: data.address.label,
        country: country.CountryName,
        // ...coords,
      };

      const location = await add(SERVICES.LOCATION, request);
      if (location?.error) {
        // console.log("location : ", location);
        alert(location?.error);
        setLoading(false);
        return;
      } else if (location) {
        dispatch(actions.setLocations([...locations, location]));
      }
    }
    setLoading(false);
    props.onClose();
  };

  return (
    <Modal
      title={"Location"}
      visible={props.visible}
      confirmLoading={loading}
      onOk={handleSubmit(onSave)}
      onCancel={props.onClose}
      okText="Save"
      cancelText="Cancel"
    >
      <FormProvider {...methods}>
        <Wrapper>
          {!props.branchId && user?.entity !== "branch" ? (
            <>
              <section>
                <BranchSelection required={true} />
              </section>
            </>
          ) : (
            <div />
          )}
          <InputController
            label="Location name"
            name="name"
            rules={{ required: true }}
          />
          <InputController
            label="Location number"
            name="number"
            rules={{ required: false }}
          />
          {props.data?.id && (
            <span>
              <b>Current address: </b>
              {props.data.address}
            </span>
          )}
          <GooglePlacesAutocompleteController
            rules={{ required: true }}
            label="Address"
            name="address"
          />

          <InputController
            label="Latitude"
            name="latitude"
            rules={{ required: true }}
          />

          <InputController
            label="Longitude"
            name="longitude"
            rules={{ required: true }}
          />
          <InputController
            label="Timezone"
            name="timezone"
            rules={{ required: true }}
          />

          <SelectController
            name="country"
            label="Select Country"
            rules={{ required: true }}
            options={countries}
            valueType="json"
            optionValue="CountryName"
            placeholder="Please select"
          />
          <InputController
            label="City"
            name="city"
            rules={{ required: true }}
          />
          <InputController
            label="Tag ID"
            name="tag_id"
            rules={{ required: false }}
          />
          <CheckBoxController name="force_tag_id">
            Force TAG ID to checkin/checkout
          </CheckBoxController>
        </Wrapper>
      </FormProvider>
    </Modal>
  );
};

const Wrapper = styled.form`
  gap: 15px;
  display: grid;
`;

export default NewLocation;
