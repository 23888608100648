const actions = {
  SET_ALL_CONTRACTORS: "SET_ALL_CONTRACTORS",
  SET_ALL_CONTRACTOR_ACCOUNTS: "SET_ALL_CONTRACTOR_ACCOUNTS",
  SET_SUBCONTRACTOR_RATE: "SET_SUBCONTRACTOR_RATE",
  setContractors: (data) => ({
    type: actions.SET_ALL_CONTRACTORS,
    payload: data,
  }),
  setContractorAccounts: (data) => ({
    type: actions.SET_ALL_CONTRACTOR_ACCOUNTS,
    payload: data,
  }),
  setSubcontractorRates: (data) => ({
    type: actions.SET_SUBCONTRACTOR_RATE,
    payload: data,
  }),
};
export default actions;
