const actions = {
  SET_ALL_REQUESTS: "SET_ALL_REQUESTS",
  SET_ALL_EQUIPMENT: "SET_ALL_EQUIPMENT",
  SET_ALL_EQUIPMENT_RATE: "SET_ALL_EQUIPMENT_RATE",
  SET_LOADING: "SET_LOADING",
  setLoading: (loading) => ({
    type: actions.SET_LOADING,
    payload: loading,
  }),
  setRequests: (data) => ({
    type: actions.SET_ALL_REQUESTS,
    payload: data,
  }),
  setEquipment: (data) => ({
    type: actions.SET_ALL_EQUIPMENT,
    payload: data,
  }),
  setEquipmentRate: (data) => ({
    type: actions.SET_ALL_EQUIPMENT_RATE,
    payload: data,
  }),
};
export default actions;
