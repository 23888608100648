import { Shift } from "../apis/types";

const actions = {
  SET_ALL_SHIFT: "SET_ALL_SHIFT",

  setShifts: (data: Shift[]) => ({
    type: actions.SET_ALL_SHIFT,
    payload: data,
  }),
};
export default actions;
