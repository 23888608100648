import {Agent, Holidays, Planning, Shift} from "../../../redux/apis/types";
import moment from "moment";
import {getInvoiceShiftClientRate, getInvoiceShiftSubcRate} from "./invoiceUtils";
import {DiffHours} from "../../../redux/apis/utils";
import {any} from "prop-types";

const weekendSurchargeRate = 35;
const nightSurchargeRate = 20;
const eveningSurchargeRate = 10;
const holidaySurchargeRate = 100;

const specialHolidays = [
    { date: "12-31", startHour: 16, endHour: 24 },
    { date: "01-01", startHour: 0, endHour: 16 },
];

export const calculateSurcharge = (
    record: Shift,
    holidays: Holidays[],
    timeZoneMoment: any
): { surcharge: number; currency: string } => {
    const { start, end, planning, agent_shifts } = record;
    const country = planning?.location?.country || "Unknown";
    const timezone = planning?.location?.timezone || "UTC";
    const currency = planning?.branch_rate?.currency || "EUR";
    const hours = parseFloat(DiffHours(start, end).toFixed(2));
    const purchaseCost = parseFloat(
        getInvoiceShiftSubcRate(record, hours));
    const isSurchargeApplicable =
        record.agent_shifts.some((agentShift) => agentShift.agent?.surcharge_applicable);
    let surcharge = 0;
    if (country === "Netherlands" && isSurchargeApplicable) {
        const details = calculateHourlyDetails(start, end, holidays, timezone, timeZoneMoment)
        surcharge = parseFloat(calculateSurchargeFromHourlyDetails(details,purchaseCost, currency, hours ))
    } else {
        surcharge = parseFloat(
            calculateOtherHolidaySurcharge(
                start,
                end,
                holidays,
                purchaseCost,
                timezone,
                currency,
                timeZoneMoment,
                planning,
                hours
            ).split(" ")[0] || "0"
        );
    }

    return { surcharge, currency };
};

export const calculateHourlyDetails = (
    start: string,
    end: string,
    holidays: Holidays[],
    timezone: string,
    timeZoneMoment: (date: string, timezone: string) => moment.Moment
): { duration: number; type: string; surcharge_percentage: number }[] => {
    const startTime = timeZoneMoment(start, timezone);
    const endTime = timeZoneMoment(end, timezone);
    const hourlyDetails: { duration: number; type: string; surcharge_percentage: number; }[] = [];
    let current = moment(startTime);

    while (current.isBefore(endTime)) {
        const nextHour = moment(current).add(1, "hour");
        const hourDuration = nextHour.isAfter(endTime) ? endTime.diff(current, "minutes") / 60 : 1;
        const hour = current.hours();
        const isWeekend = current.isoWeekday() === 6 || current.isoWeekday() === 7;
        let type = "regular";
        let surcharge_percentage = 0;
        const matchingSpecialHoliday = specialHolidays.find((special) => {
            const dateMatch = current.format("MM-DD") === special.date;
            const hourMatch = hour >= special.startHour && hour < special.endHour;
            return dateMatch && hourMatch;
        });

        if (matchingSpecialHoliday) {
            const matchingHoliday = holidays.find((holiday) =>
                timeZoneMoment(holiday.date, timezone).isSame(current, "day")
            );

            if (matchingHoliday) {
                type = "specialHoliday";
                surcharge_percentage = matchingHoliday.surcharge_percentage; // Pobieramy stawkę z `holidays`
            }
        } else {
            const isExcludedHoliday = specialHolidays.some((special) =>
                current.format("MM-DD") === special.date
            );

            if (!isExcludedHoliday) {
                const matchingHoliday = holidays.find((holiday) =>
                    timeZoneMoment(holiday.date, timezone).isSame(current, "day") && holiday.country === "Netherlands"
                );

                if (matchingHoliday) {
                    type = "holiday";
                    surcharge_percentage = matchingHoliday.surcharge_percentage;
                }
            }

            if (type === "regular") {
                if (isWeekend) {
                    type = "weekend";
                    surcharge_percentage = weekendSurchargeRate;
                } else if (hour < 7) {
                    type = "night";
                    surcharge_percentage = nightSurchargeRate;
                } else if (hour >= 18 && hour < 24) {
                    type = "evening";
                    surcharge_percentage = eveningSurchargeRate;
                }
            }
        }

        hourlyDetails.push({
            duration: hourDuration,
            type,
            surcharge_percentage,
        });

        current = nextHour;
    }

    return hourlyDetails;
};



export const calculateSurchargeFromHourlyDetails = (
    hourlyDetails: { duration: number; type: string; surcharge_percentage: number }[],
    purchaseCost: number,
    currency: string,
    hours: number
): string => {
    if (hourlyDetails.length === 0) {
        return `0 ${currency}`;
    }
    const costPerHour = purchaseCost / hours;
    const totalSurchargeValue = hourlyDetails.reduce((total, { duration, surcharge_percentage }) => {
        const surchargeForHour = costPerHour * duration * (surcharge_percentage / 100);
        return total + surchargeForHour;
    }, 0);
    return `${totalSurchargeValue.toFixed(2)} ${currency}`;
};



export const calculateHourlyRange = (
    start: string,
    end: string,
    holidays: Holidays[],
    timezone: string,
    timeZoneMoment: (date: string, timezone: string) => moment.Moment
): { range: Record<string, number>; holidayRates: { type: string; rate: number }[] } => {
    const startTime = timeZoneMoment(start, timezone);
    const endTime = timeZoneMoment(end, timezone);
    const range = {
        weekend: 0,
        holiday: 0,
        specialHoliday: 0,
        night: 0,
        evening: 0,
    };
    const holidayRates: { type: string; rate: number }[] = [];
    let current = moment(startTime);
    while (current.isBefore(endTime)) {
        const nextHour = moment(current).add(1, "hour");
        const hourDuration =
            nextHour.isAfter(endTime) ? endTime.diff(current, "minutes") / 60 : 1;
        const hour = current.hours();
        const isWeekend = current.isoWeekday() === 6 || current.isoWeekday() === 7;
        const isSpecialHoliday = specialHolidays?.some((special) => {
            const dateMatch = current.format("MM-DD") === special.date;
            const hourMatch = hour >= special.startHour && hour < special.endHour;


            return dateMatch && hourMatch;
        });

        if (isSpecialHoliday) {
            range.specialHoliday += hourDuration;
        } else {
            const matchingHoliday = holidays.find((holiday) =>
                timeZoneMoment(holiday.date, timezone).isSame(current, "day")
            );

            if (matchingHoliday) {
                range.holiday += hourDuration;
                holidayRates.push({type: "holiday", rate: matchingHoliday.surcharge_percentage});
            } else {
                if (isWeekend) {
                    range.weekend += hourDuration;
                } else if (hour < 7) {
                    range.night += hourDuration;
                } else if (hour >= 18 && hour < 24) {
                    range.evening += hourDuration;
                }
            }
        }
        current = nextHour;
    }
    return { range, holidayRates };
};


export const calculateSurchargeFromRange = (
    range: Record<string, number>,
    holidayRates: { type: string; rate: number }[],
    purchaseCost: number,
    currency: string,
    hours: number
): string => {
    const totalHours =
        range.weekend +
        range.holiday +
        range.specialHoliday +
        range.night +
        range.evening;

    if (totalHours === 0) {
        return `0 ${currency}`;
    }

    const holidayRateSum = holidayRates
        .filter(({ type }) => type === "holiday")
        .reduce((acc, { rate }) => acc + rate, 0);
    const holidayRateAvg = holidayRateSum / (range.holiday || 1);
    const totalSurchargeValue =
        (purchaseCost / hours) * range.weekend * (weekendSurchargeRate / 100) +
        (purchaseCost / hours) * range.night * (nightSurchargeRate / 100) +
        (purchaseCost / hours) * range.evening * (eveningSurchargeRate / 100) +
        (purchaseCost / hours) * range.holiday * (holidaySurchargeRate / 100) +
        (purchaseCost / hours) * range.specialHoliday * (holidaySurchargeRate / 100);


    return `${totalSurchargeValue.toFixed(2)} ${currency}`;
};

export const calculateOtherHolidaySurcharge = (
    start: string,
    end: string,
    holidays: Holidays[],
    purchaseCost: number,
    timezone: string,
    currency: string,
    timeZoneMoment: any,
    planning: Planning,
    hours: number
): string => {
    const startTime = timeZoneMoment(start, timezone);
    const endTime = timeZoneMoment(end, timezone);
    const hourlyDetails: { duration: number; type: string; surcharge_percentage: number }[] = [];
    let current = moment(startTime);

    while (current.isBefore(endTime)) {
        const nextHour = moment(current).add(1, "hour");
        const hourDuration =
            nextHour.isAfter(endTime) ? endTime.diff(current, "minutes") / 60 : 1;

        let surcharge_percentage = 0;
        const matchingHoliday = holidays.find((holiday) =>
            timeZoneMoment(holiday.date, timezone).isSame(current, "day") &&
            holiday.country === planning?.location?.country
        );

        if (matchingHoliday && planning?.branch_rate?.subcontractor_rates) {
            const subcontractorRate = planning.branch_rate.subcontractor_rates.find(
                (rate: any) => rate.subcontractor_id === planning.subcontractor_id
            );

            if (subcontractorRate) {
                surcharge_percentage = subcontractorRate.holiday_rate || 0;
            }
        }

        hourlyDetails.push({
            duration: hourDuration,
            type: matchingHoliday ? "holiday" : "regular",
            surcharge_percentage,
        });

        current = nextHour;
    }

    const costPerHour = purchaseCost / hours;
    const totalSurchargeValue = hourlyDetails.reduce((total, { duration, surcharge_percentage }) => {
        const surchargeForHour = costPerHour * duration * (surcharge_percentage / 100);
        return total + surchargeForHour;
    }, 0);
    return `${totalSurchargeValue.toFixed(2)} ${currency}`;
};

