import moment from "moment";
import React, { useState } from "react";
import { Button } from "antd";
import { SERVICES, update } from "../../../redux/apis/global";
import { Location, PlanningHistory, Shift } from "../../../redux/apis/types";
import { BASE_PATH } from "../../../redux/axiosMiddleware";
import RemoteImage from "./RemoteImage";
import { variables } from "../../../assets/styles/variables";
import {
  HISTORY_STATUS_COLOR,
  stopDuration,
  timeZoneMoment,
} from "../../../redux/apis/utils";
import { isClient } from "../../../constants";
import { get } from "lodash";
import { useDispatch } from "react-redux";
import actions from "../../../redux/planning/actions";

type Props = {
  history: PlanningHistory;
  shift: Shift | undefined;
  location: Location | undefined;
};

const HistoryItem = ({ history, shift, location }: Props) => {
  const dispatch = useDispatch();
  const [loadingNext, setLoadingNext] = useState(false);
  const agentShift = shift?.agents?.find(
    (item) => item.id == history?.agent_id
  )?.pivot;

  const onNextShift = async () => {
    setLoadingNext(true);
    const resp = await update(`${SERVICES.SHIFT}/agent/confirmEnded`, null, {
      agent_shift_id: agentShift?.id,
    });
    if (resp) {
      const data = await get(SERVICES.PLANNING, history.planning_id);
      dispatch(actions.setPlanningDetails(data as any));
      // dispatch(actions.updatePlanning(data as any, history.planning_id));
      // const shift = await get(`${SERVICES.AGENT_APP}/shifts/current`);
      // dispatch(setCurrentShift(shift));
    }
    setLoadingNext(false);
  };

  return (
    <div>
      <div>
        <div
          style={{
            backgroundColor:
              HISTORY_STATUS_COLOR[history.status] || variables.PRIMARY_COLOR,
            borderRadius: 5,
            paddingLeft: 5,
            paddingRight: 5,
            color: "white",
            width: "fit-content",
          }}
        >
          {history.status.toUpperCase()}
          {" : "}
          {history.agent_shift_id}
        </div>

        <div style={{ fontWeight: "bold", fontStyle: "italic" }}>
          (shift :{" "}
          {timeZoneMoment(shift?.start, location?.timezone).format(
            "YYYY/MM/DD HH:mm"
          )}{" "}
          -{" "}
          {timeZoneMoment(shift?.end, location?.timezone).format(
            "YYYY/MM/DD HH:mm"
          )}
          )
        </div>

        {history.status === "break" && (
          <div style={{ fontWeight: "bold", fontStyle: "italic" }}>
            Duration:{" "}
            {history.break_finished_at
              ? stopDuration(
                  history.created_at,
                  moment(history.break_finished_at)
                )
              : "In progress"}
          </div>
        )}

        {history.note && (
          <div style={{ fontSize: 15 }}>
            <q>{history.note}</q>{" "}
          </div>
        )}
        {history.break_reason && (
          <div style={{ fontSize: 15 }}>
            <q>Reason: {history.break_reason}</q>{" "}
          </div>
        )}
        {history.image && (
          <RemoteImage
            url={`${BASE_PATH}/api/${SERVICES.PLANNING_HISTORY}/image/${history.request_id}/${history.image}`}
          />
        )}
        {!isClient &&
          history.status === "ended" &&
          !shift?.extra_rate &&
          agentShift?.confirm_ended === 0 && (
            <Button
              loading={loadingNext}
              disabled={loadingNext}
              onClick={() => onNextShift()}
              type="primary"
            >
              Confirm ended
            </Button>
          )}
      </div>
    </div>
  );
};

export default HistoryItem;
