export default {
  siteName: "ISOMORPHIC",
  siteIcon: "ion-flash",
  footerText: `Capital Security @ ${new Date().getFullYear()}`,
  enableAnimatedRoute: false,
  apiUrl: "http://yoursite.com/api/",
  google: {
    analyticsKey: "UA-xxxxxxxxx-1",
  },
  dashboard: "/",
};
