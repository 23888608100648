import { SERVICES, get } from "../apis/global";
import { AgentShiftStatus } from "../apis/types";
import actions from "../planning/actions";
import { State } from "../root-reducer";
import _ from "lodash";

export const ENotificationsActionTypes = {
  GET_NOTIFICATIONS: "GET_NOTIFICATIONS",
  GET_NOTIFICATIONS_SUCCESS: "GET_NOTIFICATIONS_SUCCESS",
  GET_NOTIFICATIONS_FAILED: "GET_NOTIFICATIONS_FAILED",
  GET_UNREAD_NOTIFICATIONS: "GET_UNREAD_NOTIFICATIONS",
  GET_UNREAD_NOTIFICATIONS_SUCCESS: "GET_UNREAD_NOTIFICATIONS_SUCCESS",
  GET__UNREAD_NOTIFICATIONS_FAILED: "GET__UNREAD_NOTIFICATIONS_FAILED",
  UPDATE_NOTIFICATIONS: "UPDATE_NOTIFICATIONS",
  CLEAR_UNREAD_COUNT: "CLEAR_UNREAD_COUNT",
  CLEAR_UNREAD_COUNT_SUCCESS: "CLEAR_UNREAD_COUNT_SUCCESS",
  CLEAR_UNREAD_COUNT_FAILED: "CLEAR_UNREAD_COUNT_FAILED",
};

export const notificationsActions = {
  fetchNotifications: (payload: any) => ({
    type: ENotificationsActionTypes.GET_NOTIFICATIONS,
    payload,
  }),
  fetchUnreadNotificationsCount: () => ({
    type: ENotificationsActionTypes.GET_UNREAD_NOTIFICATIONS,
  }),
  updateNotifications: (payload: any) => ({
    type: ENotificationsActionTypes.UPDATE_NOTIFICATIONS,
    payload,
  }),
  clearUnreadCount: () => ({
    type: ENotificationsActionTypes.CLEAR_UNREAD_COUNT,
  }),
  handleShiftStatusNotification(payload: any) {
    return async (dispatch: any, getState: () => State) => {
      const state: State = getState();
      const { planningDetails, planning } = state.Planning;

      const planningDetailsCopy = _.cloneDeep(
        !_.isEmpty(planningDetails)
          ? planningDetails
          : planning.find((item) => item.id === payload.message?.planning_id)
      );

      if (_.isEmpty(planningDetailsCopy)) return;

      const shiftIndex = planningDetailsCopy.shifts.findIndex(
        (shift) => shift.id === payload.message.shift_id
      );
      if (shiftIndex < 0) return;

      const agentIndex = planningDetailsCopy.shifts[
        shiftIndex
      ].agents.findIndex((agent) => agent.id === payload.message.agent_id);

      if (agentIndex < 0) return;

      planningDetailsCopy.shifts[shiftIndex].agents[agentIndex].pivot.status =
        payload.message.type;

      if (
        payload.message.type === "shift-started" ||
        payload.message.type === "shift-ended"
      ) {
        const history = await get(
          SERVICES.PLANNING_HISTORY,
          payload.message?.planning_id
        );
        planningDetailsCopy.history = history;
      }

      dispatch(
        actions.updatePlanning(planningDetailsCopy, payload.message.planning_id)
      );
    };
  },
};
