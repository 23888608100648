import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import DatePickerController from "../../../form/DatePickerController";
import InputController from "../../../form/InputController";
import SelectController from "../../../form/SelectController";

import moment, { Moment } from "moment";

import Modal from "../../../components/Feedback/Modal";
import { FormProvider, useForm } from "react-hook-form";
import { add, get, SERVICES, update } from "../../../redux/apis/global";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../../../redux/root-reducer";
import actions from "../../../redux/planning/actions";
import {
  generateEditShift,
  generateNewShift,
  generateShiftRequest,
} from "../../../redux/apis/utils";
import { Agent, Planning, Shift } from "../../../redux/apis/types";
import { isAdmin, isSubContractor } from "../../../constants";
import TextAreaController from "../../../form/TextAreaController";
import dayjs from "dayjs";
import CheckBoxController from "../../../form/CheckBoxController";

export type FormShift = {
  start_date: Moment;
  end_date: Moment;
  start_time: string;
  end_time: string;
  agents?: Array<number>;
  subcontractor_id?: number;
  note?: string;
  extra_rate: boolean;
};
type Props = {
  visible: boolean;
  onClose: () => void;
  shift?: Shift | undefined;
  planning: Planning;
  onShiftUpdated?: (shift: Shift) => void;
};
const NewShiftModal = ({
  visible,
  onClose,
  planning: planningDetails,
  onShiftUpdated,
  ...rest
}: Props) => {
  const dispatch = useDispatch();
  const [unavailableAgents, setUnavailableAgents] = useState([]);
  const [loading, setLoading] = useState(false);

  const { contractors } = useSelector((state: State) => state.Contractors);
  const { agents: agentsSelector } = useSelector(
    (state: State) => state.Agents
  );

  const methods = useForm<any>({
    defaultValues: rest.shift
      ? generateEditShift(rest.shift, planningDetails)
      : generateNewShift(planningDetails),
  });

  const { handleSubmit, watch, trigger, setValue } = methods;

  const onSave = async (data: any) => {
    setLoading(true);
    const id = rest.shift?.id;
    if (id) {
      const shift = await update(
        SERVICES.SHIFT,
        id,
        generateShiftRequest(data, planningDetails)
      );
      if (shift) {
        if (planningDetails.shifts) {
          const copy = Object.assign({}, planningDetails);
          const index = planningDetails.shifts.findIndex(
            (shift) => shift.id === id
          );
          copy.shifts[index] = Object.assign({}, shift);
          copy.shifts = [...copy.shifts];
          dispatch(actions.updatePlanning(copy));
        } else {
          onShiftUpdated?.(shift);
        }

        onClose();
      }
    } else {
      const shift = await add(
        SERVICES.SHIFT,
        generateShiftRequest(data, planningDetails)
      );
      if (shift) {
        const copy = Object.assign({}, planningDetails);
        copy.shifts = [...copy.shifts, shift];
        dispatch(actions.updatePlanning(copy));
        onClose();
      }
    }
    setLoading(false);
  };

  // const contractorId = watch("subcontractor_id");
  const startDate = watch("start_date");
  const startTime = watch("start_time");
  const endDate = watch("end_date");
  const endTime = watch("end_time");
  const agents = watch("agents");

  useEffect(() => {
    const id = rest.shift?.id;
    if (!id && agents?.length > 0) {
      setValue(
        "subcontractor_id",
        agentsSelector.find((x) => x.id == agents[0])?.subcontractor_id
      );
    }
  }, [agents]);

  useEffect(() => {
    if (endDate && endTime) {
      trigger("end_time");
    }
  }, [endDate, endTime]);

  async function checkAgentAvailability(start: string, end: string) {
    const resp = await get(`${SERVICES.AGENT}/unavailable`, undefined, {
      start,
      end,
    });

    if (resp) setUnavailableAgents(resp);
  }

  useEffect(() => {
    if (endDate && endTime && startTime && startDate) {
      checkAgentAvailability(
        `${dayjs(startDate).format("YYYY-MM-DD")} ${startTime}`,
        `${dayjs(endDate).format("YYYY-MM-DD")} ${endTime}`
      );
    }
  }, [endDate, endTime]);

  // const agents = watch("agents");
  // console.log("agents : ", agents);

  function availableBadge(agentId: number) {
    const isUnAvailable = unavailableAgents.find((item) => item == agentId);
    if (isUnAvailable) return <label style={{ color: "red" }}>busy</label>;

    return "";
  }
  return (
    <Modal
      title={`Shift Details ${rest.shift ? rest.shift?.id : ""}`}
      visible={visible}
      onOk={handleSubmit(onSave)}
      onCancel={onClose}
      okText="Save"
      confirmLoading={loading}
      cancelText="Cancel"
    >
      <FormProvider {...methods}>
        <section>
          <Row gutter={10}>
            <Col md={12} sm={24} xs={24}>
              <DatePickerController
                rules={{ required: true }}
                name={`start_date`}
                disabledDate={(currentDate: Moment) =>
                  currentDate
                    .add(1, "days")
                    .isBefore(moment(planningDetails.start))
                }
                disabled={isSubContractor}
                label="Start date"
                style={{ width: "100%" }}
              />
            </Col>
            <Col md={12} sm={24} xs={24}>
              <InputController
                name={`start_time`}
                label="Start time"
                disabled={isSubContractor}
                rules={{ required: true }}
                type="time"
                placeholder="start"
              />
            </Col>
          </Row>
        </section>
        <section>
          <Row gutter={10}>
            <Col md={12} sm={24} xs={24}>
              <DatePickerController
                rules={{ required: true }}
                disabledDate={(currentDate: Moment) =>
                  currentDate.isBefore(startDate)
                }
                disabled={isSubContractor}
                name={`end_date`}
                label="End date"
                style={{ width: "100%" }}
              />
            </Col>
            <Col md={12} sm={24} xs={24}>
              <InputController
                name={`end_time`}
                label="End time"
                disabled={isSubContractor}
                rules={{
                  required: true,
                  validate: {
                    notValid: (value: any) => {
                      const startDateTime = moment(
                        moment(startDate).format("YYYY-MM-DD") + " " + startTime
                      );
                      const endDateTime = moment(
                        moment(endDate).format("YYYY-MM-DD") + " " + value
                      );
                      return (
                        startDateTime.isBefore(endDateTime) || "not valid "
                      );
                    },
                  } as any,
                }}
                type="time"
                placeholder="start"
              />
            </Col>
          </Row>
        </section>

        <section>
          <Row>
            <div
              style={{ color: "#242b50", fontWeight: "bold" }}
            >{`Number of agents : ${planningDetails.number_of_agents}`}</div>

            <SelectController
              name="agents"
              mode="multiple"
              label="Agents (optional)"
              optionKey="id"
              // optionValue="first_name"
              // optionValue2="last_name"
              format={(value: string, obj: Agent) => (
                <div>
                  {obj.first_name} {obj.last_name} {availableBadge(obj.id)}
                </div>
              )}
              // rules={{ required: !contractorId ? true : false }}
              options={agentsSelector}
              // options={
              //   contractorId
              //     ? agentsSelector?.filter(
              //         (agent) => agent.subcontractor_id === contractorId
              //       )
              //     : agentsSelector
              // }
              placeholder="Please select"
            />
          </Row>
        </section>

        {isAdmin && (
          <section>
            <Row>
              <SelectController
                name="subcontractor_id"
                label="Subcontractor (optional)"
                optionKey="id"
                optionValue="name"
                options={contractors}
                placeholder="Please select"
              />
            </Row>
            <section>
              <Row style={{ marginTop: 10 }}>
                <CheckBoxController name="extra_rate">
                  Extra Rate
                </CheckBoxController>
              </Row>
            </section>
          </section>
        )}

        <section>
          <TextAreaController name="note" label="Note" />
        </section>
      </FormProvider>
    </Modal>
  );
};

export default NewShiftModal;
