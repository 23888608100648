const actions = {
  CHECK_AUTHORIZATION: "CHECK_AUTHORIZATION",
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGOUT: "LOGOUT",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  GET_USER: "GET_USER",
  LOGIN_ERROR: "LOGIN_ERROR",
  REFRESHING_AUTH: "REFRESHING_AUTH",
  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
  loginSuccess: (token = false) => ({
    type: actions.LOGIN_SUCCESS,
    payload: { token },
  }),
  login: (token = false) => ({
    type: actions.LOGIN_REQUEST,
    payload: { token },
  }),
  getUser: (payload) => ({
    type: actions.GET_USER,
    payload,
  }),
  logout: () => ({
    type: actions.LOGOUT,
  }),
  refreshing: (isRefreshing) => ({
    type: actions.REFRESHING_AUTH,
    payload: isRefreshing,
  }),
};
export default actions;
