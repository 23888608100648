import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import App from "../redux/app/reducer";
import Auth from "../redux/auth/reducer";
import Clients from "../redux/clients/reducer";
import chat from "../redux/chat/reducer";
import Requests from "../redux/requests/reducer";
import Agents from "../redux/agents/reducer";
import Contractors from "../redux/contractors/reducer";
import Notification from "../redux/notifications/reducer";
import PlanningReducer from "../redux/planning/reducer";
import ShiftReducer from "../redux/shifts/reducer";
import ThemeSwitcher from "../redux/themeSwitcher/reducer";
import LanguageSwitcher from "../redux/languageSwitcher/reducer";
import { History } from "history";
import {
  Branch,
  Client,
  Contractor,
  Equipment,
  Location,
  Request,
  Planning,
  UserAccount,
  Agent,
  License,
  Contact,
  BranchRate,
  SubcontractorRate,
  AgentRate,
  Holidays,
  Shift,
  AgendaForm,
} from "./apis/types";
// import Mails from "@iso/redux/mail/reducer";
// import Calendar from "@iso/redux/calendar/reducer";
// import Box from "@iso/redux/box/reducer";
// import Notes from "@iso/redux/notes/reducer";
// import Todos from "@iso/redux/todos/reducer";
// import Contacts from "@iso/redux/contacts/reducer";
// import Cards from "@iso/redux/card/reducer";
// import Chat from "@iso/redux/chat/reducers";
// import DynamicChartComponent from "@iso/redux/dynamicEchart/reducer";
// import Ecommerce from "@iso/redux/ecommerce/reducer";
// import Invoices from "@iso/redux/invoice/reducer";

// import YoutubeSearch from "@iso/redux/youtubeSearch/reducers";
// import Articles from "@iso/redux/articles/reducers";
// import Investors from "@iso/redux/investors/reducers";
// import scrumBoard from "@iso/redux/scrumBoard/reducer";
// import drawer from "@iso/redux/drawer/reducer";
// import modal from "@iso/redux/modal/reducer";
// import profile from "@iso/redux/profile/reducer";
// import githubSearch from "@iso/redux/githubSearch/reducers";
// import quiz from "@iso/redux/quiz/reducer";

export type State = {
  Notification: {
    notifications: any[];
    unread: number;
    paginatedRecentNotificationsUrl: string | number;
    loading: boolean;
  };
  Auth: {
    idToken: string;
    refreshing: boolean;
    user: UserAccount;
  };
  Clients: {
    clients: Client[];
    branches: Branch[];
    accounts: UserAccount[];
    locations: Location[];
    contacts: Contact[];
    branchRates: BranchRate[];
  };
  Contractors: {
    contractors: Contractor[];
    accounts: UserAccount[];
    subcRates: SubcontractorRate[];
  };
  Requests: {
    requests: Array<Request>;
    equipment: Array<Equipment>;
    equipmentRates: Array<Equipment>;
    loading: boolean;
  };
  Planning: {
    planning: Array<Planning>;
    planningDetails: Planning;
    holidays: Holidays[];
    agendaForm: AgendaForm;
  };
  Shift: {
    shifts: Shift[];
  };
  Agents: {
    agents: Array<Agent>;
    licenses: Array<License>;
    agentRates: AgentRate[];
  };
};
const appReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    Auth,
    Requests,
    Clients,
    Contractors,
    Planning: PlanningReducer,
    Shift: ShiftReducer,
    App,
    Agents,
    ThemeSwitcher,
    LanguageSwitcher,
    Notification,
    chat,
    // Mails,
    // Calendar,
    // Box,
    // Notes,
    // Todos,
    // Contacts,
    // Cards,
    // Chat,
    // DynamicChartComponent,
    // Ecommerce,
    // Invoices,
    // YoutubeSearch,
    // Articles,
    // Investors,
    // scrumBoard,
    // modal,
    // drawer,
    // profile,
    // githubSearch,
    // quiz,
  });

const createRootReducer = (history: History) => {
  return appReducer(history);
};

export default createRootReducer;
