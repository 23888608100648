import {
  AppBar,
  Box,
  makeStyles,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@material-ui/core";

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getUnreadRecentMessagesCount,
  getUnreadActiveMessagesCount,
  compareByLastMessage,
  getAllUnreadMessagesCount,
} from "../../../redux/chat/selectors";

import Button from "../../../components/uielements/button";
import { chatActions } from "../../../redux/chat/actions";
import ChatContainer from "./ChatContainer";
import ChatGroupList from "./ChatGroupList";
import ChatMessage from "./ChatMessage";
import GroupMembers from "./GroupMembers";

const style = {
  margin: 0,
  top: "auto",
  right: 50,
  bottom: 50,
  left: "auto",
  position: "fixed",
  zIndex: 999999999999999,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

const ChatGroup = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  // const activeChannels = useSelector(getSortedActiveChannels);
  const activeChannels = useSelector((state) => state.chat.activeChannels).sort(
    compareByLastMessage
  );
  const recentChannels = useSelector((state) => state.chat.recentChannels).sort(
    compareByLastMessage
  );

  // const recentChannels = useSelector(getSortedRecentChannels);

  const [selectMode, setSelectedMode] = useState(false);
  const [selectedRequests, setSelectedRequests] = useState([]);

  // const { requestsMembers } = useSelector((state) => state.requests);
  const { loading, error } = useSelector((state) => state.chat);
  const [search, setSearch] = useState("");
  const [chatVisible, setChatVisible] = React.useState(false);
  const [memberVisible, setMemberVisible] = React.useState(false);
  const unreadMessagesCount = useSelector(getAllUnreadMessagesCount);

  React.useEffect(() => {
    if (!loading && !error) {
      setSelectedRequests([]);
      setSelectedMode(false);
      setMemberVisible(false);
      setValue(0);
    }
  }, [loading, error]);
  // React.useEffect(() => {
  //   if (requestsMembers.length > 0) {
  //     setMemberVisible(true);
  //   }
  // }, [requestsMembers]);
  React.useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (value == 1) {
        dispatch(chatActions.getRecentChannels({ search, pageNumber: 1 }));
      }
      // Send Axios request here
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    // recent trips
    if (newValue == 0) {
      setSearch("");
      dispatch(chatActions.getActiveChannels());
    }
    if (newValue == 1) {
      setSearch("");
      dispatch(chatActions.getRecentChannels({ search: "", pageNumber: 1 }));
    }
  };
  const getSearchActiveChannels = () =>
    search && search != ""
      ? activeChannels.filter(
          (x) =>
            x.channels &&
            x.channels.name.toLowerCase().indexOf(search.toLowerCase()) != -1
        )
      : activeChannels;

  const onPressGroup = (channel) => {
    if (selectMode) {
      const isExist = selectedRequests.find((x) => x.id == channel.id);
      if (isExist)
        setSelectedRequests(selectedRequests.filter((x) => x.id != channel.id));
      else setSelectedRequests([...selectedRequests, channel]);
    } else {
      dispatch(chatActions.setCurrentChannel(channel));
      setChatVisible(true);
    }
  };

  const onClose = () => {
    setChatVisible(false);
  };

  const onCombine = (item) => {
    if (!selectMode) {
      setSelectedMode(true);
      setSelectedRequests([...selectedRequests, item]);
    }
  };

  const onCombineChannels = () => {
    const requestIds = [
      ...new Set(
        selectedRequests
          .map((x) => x.channels.requests.map((y) => y.request.id))
          .flat()
      ),
    ];
    const subContractorsIds = [
      ...new Set(
        selectedRequests
          .map((x) =>
            x.channels.requests.map((y) => y.request.subcontractor_id)
          )
          .flat()
      ),
    ];
    // dispatch(
    //   requestsActions.getRequestsMembers({ requestIds, subContractorsIds })
    // );
    //dialogRef.current.open();
  };

  const onCreateGroup = (name, members) => {
    const requestIds = [
      ...new Set(
        selectedRequests
          .map((x) => x.channels.requests.map((y) => y.request.id))
          .flat()
      ),
    ];
    dispatch(
      chatActions.createNewChannel({
        name,
        // members: members.filter((x) => x != user.id),
        members,
        tempId: Math.random(),
        requestIds,
      })
    );
  };

  const setReadAllMessages = () => {
    dispatch(chatActions.setReadAllMessages());
  };

  return (
    <ChatContainer>
      <TextField
        fullWidth
        onChange={(e) => setSearch(e.target.value)}
        id="filled-basic"
        label="Search group"
        variant="filled"
      />
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <Tab label={`Active (${activeChannels.length})`} {...a11yProps(0)} />
          <Tab label="Recent" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      {selectMode && (
        <div
          style={{
            display: "flex",
            zIndex: -1,
            flexDirection: "row",
            justifyContent: "space-between",
            margin: 20,
            marginTop: 10,
            marginBottom: 10,
          }}
        >
          <Button
            style={{ container: { height: 50 } }}
            onClick={onCombineChannels}
            color="primary"
            disabled={selectedRequests.length <= 1}
            raised
          >{`Combine ${selectedRequests.length} groups`}</Button>
          <Button
            style={{ container: { height: 50 } }}
            onClick={() => {
              setSelectedMode(false);
              setSelectedRequests([]);
            }}
            color="default"
            raised
            icon="close"
          >
            Cancel
          </Button>
        </div>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: 10,
          marginRight: 10,
        }}
      >
        <Button
          onClick={setReadAllMessages}
          // disabled={unreadMessagesCount === 0}
          type="primary"
        >
          Clear unread messages
        </Button>
      </div>
      <TabPanel value={value} index={0}>
        <ChatGroupList
          selectMode={selectMode}
          selectedRequests={selectedRequests}
          data={getSearchActiveChannels()}
          onClick={onPressGroup}
          onCombine={onCombine}
          type="active"
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ChatGroupList
          selectMode={selectMode}
          selectedRequests={selectedRequests}
          data={recentChannels}
          onClick={onPressGroup}
          onCombine={onCombine}
          search={search}
          type="recent"
        />
      </TabPanel>
      {chatVisible && <ChatMessage onClose={onClose} />}
      {memberVisible && (
        <GroupMembers
          requests={selectedRequests}
          onClose={() => setMemberVisible(false)}
          onCreate={onCreateGroup}
        />
      )}
    </ChatContainer>
  );
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default ChatGroup;
