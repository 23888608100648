import moment, { Moment } from "moment";
import { FormShift } from "../../containers/client/PlanningDetails/NewShiftModal";
import { Planning, Request, RequestAction, Shift } from "./types";
import dayjs from "dayjs";

export const prepareConfirmRequestPost = (
  actions: RequestAction[],
  request: Request
) => {
  const data = {
    request_id: request.id,
    request_timeslots: actions,
    request_cc: [],
  };

  return data;
};
export const prepareRequestEquipment = (
  equipmentRange: any,
  branchId: number,
  locationId: number,
  currency: number
) => {
  const form = [...equipmentRange];

  for (let i = 0; i < form.length; i++) {
    form[i].start = moment(form[i].date_range[0]).format("YYYY-MM-DD");
    form[i].end = moment(form[i].date_range[1]).format("YYYY-MM-DD 23:59");
    form[i].currency = currency;
    form[i].branch_id = branchId;
    for (let j = 0; j < form[i].equipment.length; j++) {
      form[i].equipment[j].price = form[i].equipment[j].price * 100;
    }
    form[i].location_id = locationId;
    delete form[i].date_range;
    if (!form[i].subcontractor_id) delete form[i].subcontractor_id;
  }

  return form;
};
export const prepareRequestPost = (data: any) => {
  const request: any = {
    client_id: data.client_id,
    branch_id: data.branch_id,
    location_id: data.location_id,
    po_number: data.po_number,
    note: data.note,
  };

  const planningForm = data.request_timeslots;
  let planning: any = [];
  for (let i = 0; i < planningForm.length; i++) {
    // const continuous = planningForm[i].continuous;
    planning.push({
      start: dayjs(planningForm[i].date_range[0]).format("YYYY-MM-DD"),
      end: dayjs(planningForm[i].date_range[1]).format("YYYY-MM-DD"),
      continuous: planningForm[i].continuous,
      request_type: planningForm[i].request_type,
      note: planningForm[i].note,
      timeslots: planningForm[i].timeslots,
      // timeslots: continuous
      //   ? planningForm[i].timeslots[0]
      //   : planningForm[i].timeslots,
    });
  }

  return { request, planning, request_cc: [] };
};

export const generateActionLabel = (
  actions: Array<any>,
  key: "action" | "subcontractor_status"
) => {
  if (!actions) return "";
  const acceptedSlotsCount = actions.filter(
    (item) => item[key] === "confirmed"
  ).length;
  const rejectedSlotsCount = actions.filter(
    (item) => item[key] === "rejected"
  ).length;
  // if (acceptedSlotsCount > 0 && acceptedSlotsCount === actions.length)
  //   return "Confirm all";
  // if (rejectedSlotsCount > 0 && rejectedSlotsCount === actions.length)
  //   return "Reject all";

  if (acceptedSlotsCount > 0 && rejectedSlotsCount === 0) {
    return `accept ${acceptedSlotsCount}`;
  }
  if (acceptedSlotsCount === 0 && rejectedSlotsCount > 0) {
    return `reject ${rejectedSlotsCount}`;
  }

  return `confirm ${acceptedSlotsCount} and reject ${rejectedSlotsCount}`;
};

export const generateConfirmShiftsData = (reviewShifts: any) => {
  return {
    shifts: Object.keys(reviewShifts).map((key) => ({
      id: key,
      subcontractor_status: reviewShifts[key],
    })),
  };
};
export const generateEditRequest = (request: Request) => {
  for (let i = 0; i < request.request_timeslots.length; i++) {
    request.request_timeslots[i].date_range = [
      dayjs(request.request_timeslots[i].start),
      dayjs(request.request_timeslots[i].end),
    ];
  }

  (request as any).request_equipment_range = [];

  return request;
};
export const generateEditRequestEquipment = (equipment: any) => {
  for (let i = 0; i < equipment.length; i++) {
    equipment[i].date_range = [
      dayjs(equipment[i].start),
      dayjs(equipment[i].end),
    ];

    for (let j = 0; j < equipment[i].equipment.length; j++) {
      equipment[i].equipment[j].price = equipment[i].equipment[j].price / 100;
    }
  }

  return equipment;
};

export const generateHistoryRequest = (data: any, planning: Planning) => {
  let request: any;

  request = {
    created_at: data.created_at,
    agent_id: data.agent_id,
    note: data.note,
    break_reason: data.break_reason,
    status: data.status,
    planning_id: data.planning_id,
    request_id: data.request_id,
    shift_id: data.shift_id,
    agent_shift_id: data.agent_shift_id,
  };
  if (data.break_finished_at) {
    request.break_finished_at = data.break_finished_at;
  }

  return request;
};
export const generateShiftRequest = (data: FormShift, planning: Planning) => {
  const request: any = {
    start: moment(`${data.start_date.format("YYYY-MM-DD")} ${data.start_time}`)
      .utc()
      .format("YYYY-MM-DD HH:mm:ss"),
    end: moment(`${data.end_date.format("YYYY-MM-DD")} ${data.end_time}`)
      .utc()
      .format("YYYY-MM-DD HH:mm:ss"),
    planning_id: planning.id,
    request_id: planning.request_id,
    note: data.note,
    extra_rate: data.extra_rate,
    branch_rate_id: data.extra_rate
      ? planning?.extra_branch_rate_id
      : undefined,
  };

  if (data.subcontractor_id) {
    request.subcontractor_id = data.subcontractor_id;
    request.subcontractor_status = "pending";
  }
  if (data.agents && data.agents.length > 0) {
    request.agents = data.agents;
  }

  return request;
};

export const generateNewShift = (planning: Planning) => {
  const hours = DiffHours(planning.start, planning.end);
  // if (planning.shifts.length === 0) {
  return {
    start_date: timeZoneMoment(planning.start, planning.location?.timezone),
    start_time: timeZoneMoment(
      planning.start,
      planning?.location?.timezone
    ).format("HH:mm"),
    end_date: timeZoneMoment(planning.end, planning.location?.timezone),
    end_time: timeZoneMoment(planning.end, planning.location?.timezone).format(
      "HH:mm"
    ),
  };

  // if (hours > 24) return obj;
  // return {
  //   ...obj,
  //   end_date: timeZoneMoment(planning.end, planning.location?.timezone),
  //   end_time: timeZoneMoment(planning.end, planning.location?.timezone).format(
  //     "HH:mm"
  //   ),
  // };
  // }

  const lastShift = planning.shifts[planning.shifts.length - 1];

  const obj: any = {
    start_date: timeZoneMoment(lastShift?.end, planning.location?.timezone),
    start_time: timeZoneMoment(
      lastShift?.end,
      planning.location?.timezone
    ).format("HH:mm"),
    end_date: timeZoneMoment(lastShift?.end, planning.location?.timezone),
  };

  // if (planning?.subcontractor) {
  //   obj.subcontractor_id = planning?.subcontractor_id;
  // }
  return obj;
};

export const generateEditShift = (shift: Shift, planning: Planning) => {
  const startDate = timeZoneMoment(shift.start, planning?.location?.timezone);
  const endDate = timeZoneMoment(shift.end, planning?.location?.timezone);
  const form: any = {
    id: shift.id,
    start_date: startDate,
    end_date: endDate,
    start_time: startDate.format("HH:mm"),
    end_time: endDate.format("HH:mm"),
    subcontractor_id: shift.subcontractor_id,
    note: shift.note,
    extra_rate: shift.extra_rate,
    branch_rate_id: shift.branch_rate_id,
  };

  if (shift.agents && shift.agents.length > 0) {
    form.agents = shift.agents.map((agent) => agent.id);
  }

  return form;
};

export const defaultFormPlanningDetails = (planning: Planning) => {
  const startDate = timeZoneMoment(planning.start, planning.location?.timezone);
  const endDate = timeZoneMoment(planning.end, planning.location?.timezone);

  return {
    id: planning.id,
    start_date: startDate,
    end_date: endDate,
    start_time: startDate.format("HH:mm"),
    end_time: endDate.format("HH:mm"),
    branch_id: planning.branch_id,
    branch_rate_id: planning.branch_rate_id,
    extra_branch_rate_id: planning.extra_branch_rate_id,
    extra_number_of_agents: planning.extra_number_of_agents,
    extra_hours: planning.extra_hours,
    po_number: planning?.request?.po_number,
    note: planning.note || planning?.request.note,
    number_of_agents: planning?.number_of_agents,
  };
};

export const timeZoneMoment = (
  date: string | undefined,
  timeZone: string | undefined
) => {
  if (!date || !timeZone) return moment();
  return moment(
    moment.utc(date).toDate().toLocaleString("en-US", { timeZone })
  );
};

export const DiffDays = (start: Moment, end: Moment) => {
  // const startDay = start.get("D");
  // const endDay = end.get("D");
  // return endDay - startDay;
  return end.diff(start, "days");
};
export const DiffHours = (start: string, end: string) => {
  const duration = moment.duration(moment(end).diff(moment(start)));
  if (!duration) return 0;
  return duration.asMilliseconds() / (1000 * 60 * 60);
  var hours = duration.asHours();
  return hours;
};

export const STATUS_COLORS: { [key: string]: string } = {
  pending: "orange",
  confirmed: "green",
  finished: "#242b50",
  rejected: "red",
  cancelled: "red",
  archived: "#242b50",
  started: "green",
};

export const HISTORY_STATUS_COLOR = {
  started: "green",
  ended: "red",
  break: "orange",
  note: null,
  image: null,
  cost: null,
};

export const stopDuration = (
  date: string,
  endDate?: Moment,
  format?: string
) => {
  const startDate = moment(date);
  const today = endDate || moment();
  let delta = today.diff(startDate, "seconds");
  return moment({ h: 0, m: 0, s: 0, ms: 0 })
    .add(delta, "second")
    .format(format ? format : "H:mm");
};

export const getPlanningSubcontractors = (planning: Planning) => {
  if (!planning.has_shifts && planning.subcontractor_id)
    return planning.subcontractor?.name;

  const shifts = planning.shifts
    .filter((shift) => !!shift.subcontractor_id)
    .map((shift) => shift.subcontractor?.name);

  // @ts-ignore
  const uniq = [...new Set(shifts)];
  return uniq.join(", ");
};

// {
// 	"request":{
// 	   "equipment":"[1,2]",
// 	    "client_id":1,
// 	    "branch_id":1,
// 	    "location_id":1,
// 	    "po_number":"PO1",
// 	    "identifier":"45439555",
// 	    "note":"important note here"
//     },
//     "planning":[
//         {
//         	"start":"2022-06-01 11:28",
//         	"end":"2022-06-05 11:28",
//         	"continuous": false,
//         	"request_type":"Static",
//             "note":"note",
//             "timeslots":[{"week_days":["Monday","Tuesday","Wednesday"],"start_time":"10:00","end_time":"17:00","number_of_agents":"2"},{"week_days":["Sunday","Saturday"],"start_time":"22:00","end_time":"08:00","number_of_agents":"4"}]
//         },
//         {
//             "start":"2022-06-01 11:28",
//         	"end":"2022-06-05 11:28",
//             "continuous":true,
//             "request_type":"Close protection",
//             "note": "note",
//             "timeslots":[
//                 {
//                     "start_time":"10:00","end_time":"08:00","number_of_agents":"10"
//                 }
//             ]
//         }
//     ]
// }

export const groupBy = function (xs: any, key: any) {
  return xs.reduce(function (rv: any, x: any) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const getDefaultPath = () => {
  // if (isSubContractor) return "/dashboard/planning";
  return "/dashboard";
};

export const isDev = () => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    return true;
  }

  return false;
};
