import React, { useState } from "react";
import { ButtonSmall, Wrapper } from "./Calendar.styles";
import { PlusOutlined } from "@ant-design/icons";
import NewClient from "../../admin/clients/NewClient";
import SelectController from "../../../form/SelectController";
import { useSelector } from "react-redux";
import { State } from "../../../redux/root-reducer";

const ClientSelection = () => {
  const [visible, setVisible] = useState(false);
  const { clients } = useSelector((state: State) => state.Clients);

  return (
    <>
      <Wrapper>
        <h4>Select client</h4>
        <ButtonSmall
          style={{ height: 24 }}
          size="small"
          icon={<PlusOutlined />}
          onClick={() => setVisible(true)}
          type="primary"
        />
      </Wrapper>
      <SelectController
        name="client_id"
        rules={{ required: true }}
        options={clients}
        optionValue="name"
        optionKey="id"
        placeholder="Please select"
      />
      {visible && (
        <NewClient onClose={() => setVisible(false)} visible={visible} />
      )}
    </>
  );
};

export default ClientSelection;
