import axios from "axios";

export const BASE_PATH = process.env.REACT_APP_BASE_URL as string;

const createAxiosInstance = (baseURL: string) =>
  axios.create({
    baseURL,
    responseType: "json",
    withCredentials: true,
  });

export const axiosWithToken = createAxiosInstance(BASE_PATH);
export const axiosWithoutToken = createAxiosInstance(BASE_PATH);
