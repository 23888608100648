import actions from "./actions";

const initState = {
  shifts: [],
};

export default function shiftsReducer(state = initState, action) {
  switch (action.type) {
    case actions.SET_ALL_SHIFT:
      return {
        ...state,
        shifts: action.payload,
      };
    default:
      return state;
  }
}
